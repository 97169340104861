import React from "react";
import ThankyouFooter from '../Includes/Layouts/ThankyouFooter';
import "../../assets/Suppliers/suppliers.scss";

const Suppliers = () => {
  return ( 
    <>
    <div className="suppliers_page">
      <header className="supp">
      <div className="col-lg-12 text-center">
        <img src="/assets/Thankyou/img/logo.png" alt="logo" className="logo"/> 
      </div>
    </header>
      <section className="suppliers-list">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2>supplier list</h2>
            </div>
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>1 Stop Insurance Consultants</h3>
                  <p> Wallington</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Folgate Credit Risk Solutions</h3>
                  <p>South Milton Keynes</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Norton Insurance</h3>
                  <p>Edgbaston</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>1st Choice Insurance</h3>
                  <p> Shrewsbury</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Ford Brokers</h3>
                  <p>Swansea</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>O Connor &amp; Co (Insurances) Ltd</h3>
                  <p>Northampton</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>A J Insurance Service Limited</h3>
                  <p>Tilbury</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Forfar Financial Ltd</h3>
                  <p>Forfar</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Oconnor &amp; Co (Insurances) Ltd</h3>
                  <p>Northampton</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>A R T Jeffries</h3>
                  <p>Titchfield</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Forfar Insurance Brokers Ltd</h3>
                  <p>Forfar</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Omni (Brokers) Limited</h3>
                  <p>Bognor Regis</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>A Razzaq Insurance Brokers</h3>
                  <p>Oldbury</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Freedom Health Insurance</h3>
                  <p>Poole</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Orb Insurance Services</h3>
                  <p>Talbot Green</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Academy (Commercial) Ins Services Ltd</h3>
                  <p>Basingstoke</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Gateway Insurance Services Limited</h3>
                  <p>Edinburgh</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Orchard Independent</h3>
                  <p>Edinburgh</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Academy Insurance Services Ltd</h3>
                  <p>Swindon</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>GB Underwriting Ltd</h3>
                  <p>Little Braxted</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Osborne &amp; Sons (Insurance Consultants) Ltd</h3>
                  <p>Sutton</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Academy Insurance Services Ltd</h3>
                  <p>Staines</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Geoffrey Simmons Ins Consultants LLP</h3>
                  <p>Gravesend</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Osborne &amp; Sons (Taxi Insurance Services)</h3>
                  <p>Sutton</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Academy Insurance Services Ltd</h3>
                  <p>Reading</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Giles (Alton) Ltd</h3>
                  <p>Alton</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Owen &amp; Ewing Insurance Brokers</h3>
                  <p>Oswaldtwistle</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Academy Insurance Services Ltd</h3>
                  <p>Avonmouth</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Golfguard Ltd</h3>
                  <p>East Grinstead</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>P J Insurance Brokers Ltd</h3>
                  <p>Weybridge</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Academy Insurance Services Ltd</h3>
                  <p>Slough</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Goodwin Best</h3>
                  <p>Oldham</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>P K Insurance (Brokers) Ltd</h3>
                  <p>Croydon</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Academy Insurance Services Ltd</h3>
                  <p>Basildon</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Gott &amp; Wynne Ins Brokers</h3>
                  <p>Llandudno</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Panda Insurance</h3>
                  <p>Mildenhall</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Access Underwriting Ltd</h3>
                  <p>South Croydon</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Graham Brown Insurance Brokers Ltd</h3>
                  <p>Padstow</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Park Insurance Services</h3>
                  <p>Bristol</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Active Brokers</h3>
                  <p>Chelmsford</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Green Insurance Group</h3>
                  <p>Bexhill-on-Sea</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Peak Insurance Services Limited</h3>
                  <p>Matlock</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Active Insurance Services</h3>
                  <p>Manchester</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Griffin Commercial Insurance Brokers Ltd</h3>
                  <p>Exeter</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Peter Best Insurance Services Ltd</h3>
                  <p>Colchester</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Adler Insurance Brokers</h3>
                  <p>Birmingham</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Hallsdale Insurance Brokers Ltd</h3>
                  <p>Kettering</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Peter McAleer Ltd</h3>
                  <p>Omagh</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>AIB Airsports Insurance Bureau Ltd</h3>
                  <p>Eastleigh</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Halton Insurance Services</h3>
                  <p>Widnes</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>PHA</h3>
                  <p>Sidcup</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Ainsbury Insurance Brokers</h3>
                  <p>Swinton</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Hamilton Robertson Insurance Brokers</h3>
                  <p>Kirkintilloch</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Philip Gibbs Insurance Brokers</h3>
                  <p>Portsmouth</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>AIPS Ltd</h3>
                  <p>Mansfield</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Harborough Portas Ltd</h3>
                  <p>Syston</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Plaice Thompson &amp; Richardson Ltd</h3>
                  <p>Grantham</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>AJP Partnership</h3>
                  <p>Clevedon</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Harrison Beaumont Insurance Services Ltd</h3>
                  <p>Witney</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Plan Insurance Brokers</h3>
                  <p>Redhill</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Alan Boswell Insurance Group</h3>
                  <p>Norwich</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Harry Fort Ins Brokers</h3>
                  <p>Sherborne</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Polished Insurance</h3>
                  <p>Wigan</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Aldium Independent Insurance services Ltd</h3>
                  <p>Neston</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Heath Crawford</h3>
                  <p>Watford</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Prestige Underwriting Services Limited</h3>
                  <p>Basildon</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Alker Brothers &amp; Partners</h3>
                  <p>Wigan</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Helm Group UK plc</h3>
                  <p>Ashford</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Priestleys</h3>
                  <p>Altrincham</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Allcover Insurance Brokers Ltd</h3>
                  <p>London</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Henshalls Insurance Brokers</h3>
                  <p>Newport</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Prizm Solutions Limited</h3>
                  <p>Tamworth</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Allied Westminster (Insurance Services)</h3>
                  <p>Boston Spa</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Hera Indemnity</h3>
                  <p>London</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Prosure Insurance Services</h3>
                  <p>Melksham</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Alpha Travel Insurance</h3>
                  <p>West Malling</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Highriskliability24-7</h3>
                  <p>Lutterworth</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Quote Chief Ltd</h3>
                  <p>Manchester</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Anchor Mortgages Limited</h3>
                  <p>Shoreham by Sea</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Holbrook Insurance Brokers</h3>
                  <p>Godalming</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Quote Dave</h3>
                  <p>Southport</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Anderson White Insurance Brokers Limited</h3>
                  <p>Horsham</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Horse and Pony Insurance</h3>
                  <p>Halifax</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Quote Me today</h3>
                  <p>Faversham</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Animal Friends Insurance</h3>
                  <p>Amesbury</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Howe Maxted Group</h3>
                  <p>Sidcup</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Quote4insurance</h3>
                  <p>Potters Bars</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>APlus Insurance</h3>
                  <p>Hemel Hempstead</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Hoyland Insurance Brokers Ltd</h3>
                  <p>Barnsley</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Quoteline Direct</h3>
                  <p>Manchester</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Arborplan Insurance</h3>
                  <p>Cranleigh</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Hughes and Company</h3>
                  <p>Newtownards</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Quotemeless</h3>
                  <p>Worcester</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Archibald Reid</h3>
                  <p>London</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Ian Brown General Insurance Brokers</h3>
                  <p>Billericay</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>R A Insurance Brokers Limited</h3>
                  <p>Croydon</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Arkwright Insurance Brokers Limited</h3>
                  <p>Bolton</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>IHN Limited</h3>
                  <p>Stourbridge</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>R G Ford Insurance Brokers Ltd</h3>
                  <p>Chelmsford</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Arlene Jardine Ltd</h3>
                  <p>Dungannon</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Independent Ins Brokers Ltd</h3>
                  <p>Aberdeen</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>R T Williams Independent Financial Advisers Ltd</h3>
                  <p>Brighton</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Ashley Page International Insurance Brokers</h3>
                  <p>London</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Insurance Advisory Service</h3>
                  <p>Stourbridge</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>R T Williams Insurance Brokers Ltd</h3>
                  <p>Brighton</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>ASM Horwath Financial Consultants</h3>
                  <p>Belfast</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Insurance Management Group Ltd</h3>
                  <p>Glasgow</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Real Insurance Solutions</h3>
                  <p>Cambridge</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Assetsure</h3>
                  <p>London</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Insurance People</h3>
                  <p>Tonbridge</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Realty Insurances Ltd</h3>
                  <p>London</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Astbury Wren &amp; Co Ltd</h3>
                  <p>Chester</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Insurance Revolution</h3>
                  <p>Manchester</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Reich Insurance Brokers</h3>
                  <p>Manchester</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Atlas Consulting Group Limited</h3>
                  <p>Rochdale</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Insurance Watch</h3>
                  <p>Romford</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Reid Briggs &amp; Co Ltd</h3>
                  <p>Eastbourne</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Aviation Insurance Brokers Limited</h3>
                  <p>St Peter Port</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>insureCOURIER</h3>
                  <p>Crewe</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Renovation Insurance Brokers</h3>
                  <p>London</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Banner Group</h3>
                  <p>Ipswich</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>insureFleet</h3>
                  <p>Crewe</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Rhea Professions Finance</h3>
                  <p>Prestatyn</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Baronsmead Insurance Brokers</h3>
                  <p>London</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Insurelink (East Anglia) Limited</h3>
                  <p>Lowestoft</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Rhea Professions Finance</h3>
                  <p>London</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Basic Boat Liability Company</h3>
                  <p>Sidcup</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Intelligent Broking</h3>
                  <p>London</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Rightsure</h3>
                  <p>Tolworth</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>BDB Limited</h3>
                  <p>London</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Invicta Business</h3>
                  <p>Sevenoaks</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Robert Gerrard &amp; Co Ltd</h3>
                  <p>Chingford</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>BDB UK LImited</h3>
                  <p>London</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>J &amp; A Brokers Limited</h3>
                  <p>Manchester</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Robert Nott Insurance Brokers</h3>
                  <p>Bexleyheath</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Belgravia Insurance</h3>
                  <p>London</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>J and J Insurance &amp; Financial Consultants Ltd</h3>
                  <p>Ealing</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Robins Row Limited</h3>
                  <p>Basingstoke</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Bennett Gould &amp; Partners Limited</h3>
                  <p>London</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>J Bennett &amp; Son</h3>
                  <p>High Wycombe</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Robins Row Limited</h3>
                  <p>Sudbury</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Beric Webb Associates</h3>
                  <p>Tunbridge Wells</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>J E Mottram (Insurance Brokers) Ltd</h3>
                  <p>Stockport</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Robinson Leslie Group</h3>
                  <p>Chester</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Berkeley Alexander</h3>
                  <p>Lewes</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>J H Hibbert (Insurance Brokers) Limited</h3>
                  <p>London</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Russell Scanlan Insurance Broking &amp; Risk Managing</h3>
                  <p>Nottingham</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Bernard Saxon Insurance Services Ltd</h3>
                  <p>Jesmond</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>J M Glendinning Insurance Brokers Ltd</h3>
                  <p>Ilkley</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>S A Cutler &amp; Co</h3>
                  <p>Newport</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>BGi UK Limited</h3>
                  <p>Faringdon</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>J W Group</h3>
                  <p>Edinburgh</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>S G Busby Ltd</h3>
                  <p>High Wycombe</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>BIB Darlington Ltd</h3>
                  <p>Darlington</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>J Willey &amp; Co</h3>
                  <p>Pontefract</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>S R Insurance Services</h3>
                  <p>Lowestoft</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Bishop Skinner Insurance Brokers Ltd</h3>
                  <p>Newcastle upon Tyne</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Jack Joy</h3>
                  <p>Wetherby</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>S Saunders &amp; Company</h3>
                  <p>Prestwich</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Blue Seas Adjusters</h3>
                  <p>London</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Jacksons Insurance &amp; Risk Management</h3>
                  <p>Penzance</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>S W Financial Services Ltd</h3>
                  <p>Lincoln</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>BMG Insurance</h3>
                  <p>Portadown</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>James &amp; Lindsay Ltd</h3>
                  <p>Colchester</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>S4 Financial PLC</h3>
                  <p>Blackwater</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Bond Lovis Insurance Brokers</h3>
                  <p>Basildon</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>James Hampden Ins Brokers Ltd</h3>
                  <p>Ashby de la Zouch</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Saffron Insurance</h3>
                  <p>Saffron Walden</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Boswell Financial Services</h3>
                  <p>Norwich</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Jannard Quadrant Insurance Brokers Ltd</h3>
                  <p>Watford</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Sevenoaks Insurance Brokers Ltd</h3>
                  <p>Sevenoaks</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Boswell Group</h3>
                  <p>Norwich</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>JCB Insurance Services Ltd</h3>
                  <p>Rocester</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Shevill Parkes &amp; Co Ltd</h3>
                  <p>Derby</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Brain Wilson &amp; Partners Ltd</h3>
                  <p>Lincoln</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Jelf</h3>
                  <p>Leeds</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Shield Insurance Services</h3>
                  <p>Southall</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Brentwood Insurance Services</h3>
                  <p>Brentwood</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>JLT Business Insurance Services</h3>
                  <p>Birmingham</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Sigerson Associates Limited</h3>
                  <p>Retford</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Broker Liability 24-7</h3>
                  <p>Lutterworth</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>John Bannerman Limited</h3>
                  <p>London</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Silverline Insurance</h3>
                  <p>London</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Bruce Burke &amp; Co Limited</h3>
                  <p>Goodmayes</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>JRT Insurance Brokers Ltd</h3>
                  <p>Nottingham</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Smart Cover Direct</h3>
                  <p>Watford</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Bruce Burke &amp; Co Limited</h3>
                  <p>Goodmayes</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Jukes Insurance Brokers</h3>
                  <p>Bromsgrove</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>South Downs Commercial Insurance (Brokers) Ltd</h3>
                  <p>Pevensey</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Bruce Burke &amp; Co Limited</h3>
                  <p>Goodmayes</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>K F Garner &amp; Company</h3>
                  <p>Wigan</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>South Essex Insurance Brokers Ltd</h3>
                  <p>South Ockendon</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Bruce Burke &amp; Co Limited</h3>
                  <p>Goodmayes</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Kallender Jeffries Ins Brokers Ltd</h3>
                  <p>Bath</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Square One Financial Planning</h3>
                  <p>Burgess Hill</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Bruce Burke &amp; Co Limited</h3>
                  <p>Goodmayes</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Keegan&amp; Pennykid (Ins Brokers) Ltd</h3>
                  <p>Edinburgh</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>ss</h3>
                  <p>ss</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>BTIC Ltd</h3>
                  <p>Gosforth</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Kent Insurance Brokers Ltd</h3>
                  <p>Maidstone</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Staines Insurance Consultants Ltd</h3>
                  <p>Staines</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Bucklands Insurance Brokers &amp; IFAs</h3>
                  <p>Manchester</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Kerry London Financial Services Limited</h3>
                  <p>Isleworth</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Stenning Ins Services Ltd</h3>
                  <p>Lyminge</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Business Insurance Line</h3>
                  <p>Bristol</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Kerry London Group</h3>
                  <p>Watford</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Stewart Miller McCulloch</h3>
                  <p>Oldbury</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Caddysure</h3>
                  <p>London</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Kerry London Group</h3>
                  <p>Carterton</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Stewart Miller McCulloch</h3>
                  <p>Hereford</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Calloway &amp; Sons Ins Consulants Ltd</h3>
                  <p>Chelmsford</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Kerry London Group</h3>
                  <p>Exeter</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Sturdy Edwards (Financial Services) Limited</h3>
                  <p>East Grinstead</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Calvert Glover &amp; Co Ltd</h3>
                  <p>Bradford</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Kerry London Group</h3>
                  <p>London</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Sturdy Edwards (Insurance Brokers) Limited</h3>
                  <p>East Grinstead</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Campbell Irvine Ltd</h3>
                  <p>London</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Kerry London Group</h3>
                  <p>Isleworth</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Subex Insurance</h3>
                  <p>Sidcup</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>CBI Insurance Consultants</h3>
                  <p>Colwyn Bay</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Kit Car Insurance</h3>
                  <p>Sutton</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Sunninghill Insurance Brokers Ltd</h3>
                  <p>Aldershot</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>CCV Underwriting Sports and Leisure</h3>
                  <p>Romford</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Laing Markey Lyness</h3>
                  <p>Belfast</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Sure Wise Limited</h3>
                  <p>South Benfleet</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Central Insurance Services Ltd</h3>
                  <p>Aberdeen</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Larsen Howie</h3>
                  <p>Melton Mowbray</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Sutcliffe and Co</h3>
                  <p>Worcester</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Central Investment Services</h3>
                  <p>Aberdeen</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Lawsons Insurance Brokers</h3>
                  <p>Birmingham</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Tarleton Hodgson &amp; Son Ltd</h3>
                  <p>Douglas</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Cheers Insurance Brokers Ltd</h3>
                  <p>Hampton</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Letrisks (Southampton)</h3>
                  <p>Eastleigh</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Team One</h3>
                  <p>Margate</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Chess Financial Services</h3>
                  <p>Hayes</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Liability24-7</h3>
                  <p>Lutterworth</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Tempcover Ltd</h3>
                  <p>Fleet</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Chiltern Insurance Group</h3>
                  <p>Pangbourne</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Lighthouse Insurance Brokers Limited</h3>
                  <p>Derby</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>test</h3>
                  <p>test</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Chris Semmens Commercial Insurance Brokers</h3>
                  <p>Baldock</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Longmynd Insurance</h3>
                  <p>Church Stretton</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>The F G Watts Partnership</h3>
                  <p>Northampton</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>City Insurance Group</h3>
                  <p>Winchester</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Lorica Direct</h3>
                  <p>Birmingham</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>The Family Solutions</h3>
                  <p>Sheffield</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Classic Bowrid Insurance Brokers Ltd</h3>
                  <p>London</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Lorica Insurance Brokers</h3>
                  <p>Hemel Hempstead</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Three Counties Insurance Brokers Ltd</h3>
                  <p>Stratford-upon-Avon</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Clear Insurance Management Limited</h3>
                  <p>London</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Lothian Insurance Brokers Limited</h3>
                  <p>Edinburgh</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>TIC (UK) Ltd</h3>
                  <p>Tyldesley</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Club Insure Limited</h3>
                  <p>Yeadon</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Loveit Coverit</h3>
                  <p>Southend-on-Sea</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>TIC (UK) Ltd</h3>
                  <p>Poulton Le Fylde</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Coggans Wood</h3>
                  <p>Edinburgh</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Luker Rowe</h3>
                  <p>Old Amersham</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>TIC (UK) Ltd</h3>
                  <p>Lancaster</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Collins Halden &amp; Burnett Ltd</h3>
                  <p>Aberdeen</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Lynbrook Insurance (ERS)</h3>
                  <p>Brentwood</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Tinhat Insurance Services</h3>
                  <p>Oxford</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Northern Alliance Brokers Limited</h3>
                  <p>Wakefiield</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>FML Insurance Services Ltd</h3>
                  <p>Westcliff-on-Sea</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>XSPL.co.uk</h3>
                  <p>Little Braxted</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>NIB Insurance Brokers Limited</h3>
                  <p>Brighton</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Flag Insurance (Brokers) Limited</h3>
                  <p>Bracknell</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>WW Group Limited</h3>
                  <p>Lutterworth</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Network Trade Insurance Services Limited</h3>
                  <p>Preston</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Firth &amp; Scott (Insurance Brokers) Ltd</h3>
                  <p>Sherwood</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>WPS Insurance Brokers</h3>
                  <p>Plymouth</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>N Bellingall (Ins Services) Ltd</h3>
                  <p>Thornton Cleveleys</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Fenton Insurance Solutions</h3>
                  <p>King's Lynn</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Worry and Peace</h3>
                  <p>Winchmore Hill</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Music Insurance Brokers</h3>
                  <p>London</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Fehnert plc</h3>
                  <p>Market Harborough</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Worrell Fry &amp; Co Limited</h3>
                  <p>Bognor Regis</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>MPRS Ltd</h3>
                  <p>Bristol</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>FD Protect Insurance Brokers</h3>
                  <p>Bristol</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Workspace Plus Insurance</h3>
                  <p>Isleworth</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Motor Trade UK</h3>
                  <p>Bolton</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Farleys Insurance</h3>
                  <p>Bradford</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Wilson Dean Financial Services</h3>
                  <p>Borehamwood</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Motor Trade Insurance Solutions</h3>
                  <p>Norwich</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>F M Green</h3>
                  <p>Witney</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>WH &amp; R McCartney Ltd</h3>
                  <p>Motherwell</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Motor Trade Insurance Services</h3>
                  <p>Croydon</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>F B Insurance Agents</h3>
                  <p>Bournville</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Westward Counties (Cullompton)</h3>
                  <p>Cullompton</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Mortgage Success</h3>
                  <p>Manchester</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Everslet Insurance Services (Maidenhead) Ltd</h3>
                  <p>Maidenhead</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Westscott Insurance Brokers</h3>
                  <p>Dumfries</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Mortgage Excellence plc</h3>
                  <p>Stanmore</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Element Hinton Insurance Brokers</h3>
                  <p>Hednesford</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Westexe (Exeter) Ltd</h3>
                  <p>Exeter</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Moreland Insurance</h3>
                  <p>Hamilton</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Element &amp; Hinton Independent Financial Advisors</h3>
                  <p>Hednesford</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Element &amp; Hinton Independent Financial Advisors</h3>
                  <p>Hednesford</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Weald Insurance Brokers Ltd</h3>
                  <p>Westerham</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Money Matters</h3>
                  <p>Crayford</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>EDN Insurance Services</h3>
                  <p>Oldham</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Waveney Read Mclaren</h3>
                  <p>Peterborough</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Moffatt Saunders</h3>
                  <p>Hertford</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Eastmills Insurance Services Ltd</h3>
                  <p>Rotherham</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Watkin Davies Insurance Consultants</h3>
                  <p>Cardiff</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Mithras Underwriting Limited</h3>
                  <p>London</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Eastlake &amp; Beachell Ltd</h3>
                  <p>Leicester</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Walter Wright (Ins Brokers) Ltd</h3>
                  <p>Gosforth</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Midlands Insurance Services</h3>
                  <p>Mansfield</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>E W Southall Insurance Brokers</h3>
                  <p>Mountain Ash</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Walker Watson Financial Advisers</h3>
                  <p>Horley</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>MIB Insurance</h3>
                  <p>London</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>E Davies (Northern)</h3>
                  <p>Oldham</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>W J Reid Henry &amp; Co</h3>
                  <p>Ballymoney</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Mercer Jones &amp; Co Ltd</h3>
                  <p>Hanley</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Droneguard</h3>
                  <p>Southwater</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>W Denis Insurance Brokers plc</h3>
                  <p>Leeds</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Medical Insurance Consultants</h3>
                  <p>Yeovil</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Drayton Insurance Services</h3>
                  <p>Norwich</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>W Burch &amp; Son Limited</h3>
                  <p>High Wycombe</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Media Insurance Brokers Limited</h3>
                  <p>London</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Douglas Insurance Brokers Ltd</h3>
                  <p>Swansea</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>W B Baxter Limited</h3>
                  <p>Ilford</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>MCM Group</h3>
                  <p>Chorlton Cum Hardy</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Dobson and Hodge Ltd</h3>
                  <p>Doncaster</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Vipin Gudka Insurance services Ltd</h3>
                  <p>Wembley</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>MCM Group</h3>
                  <p>Birmingham</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Diveinsure</h3>
                  <p>Clevedon, North Somerset</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Victoria Healthcare Management</h3>
                  <p>Polegate</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Victoria Healthcare Management</h3>
                  <p>Polegate</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>MCM Group</h3>
                  <p>Charlbury</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>David Fangen Ltd</h3>
                  <p>London</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>David Fangen Ltd</h3>
                  <p>London</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Vectis Insurance Services Ltd</h3>
                  <p>Cowes</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>McLean Associates</h3>
                  <p>Bridgnorth</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>DataHealth Consultancy Limited</h3>
                  <p>Guildford</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Vape Insure</h3>
                  <p>Wakefield</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>McAra Associates Ltd</h3>
                  <p>Aberdeen</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Cyber Liability Insurance Experts</h3>
                  <p>Uckfield</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Unique Insurance Services Limited</h3>
                  <p>Nottingham</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Martin Kemp Insurance Services</h3>
                  <p>Newport Pagnell</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Cubit Mini Cab Insurance</h3>
                  <p>London</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Underwriting 24-7</h3>
                  <p>Lutterworth</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Martin Hale &amp; Co Ltd</h3>
                  <p>Hertford</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Crystal Insurance Brokers</h3>
                  <p>London</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Under The Sun Travel Insurance Services</h3>
                  <p>Bexleyheath</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Crown House</h3>
                  <p>Nottingham</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Martin &amp; Galpin (Insurance Services) Ltd</h3>
                  <p>Knowle</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Under 35s Reinsurance Group</h3>
                  <p>London</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Marchant McKechnie Independent Financial Advisers Ltd</h3>
                  <p>Beverley</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Crosbie and Jack</h3>
                  <p>Falkirk</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>TRS Hertfordshire</h3>
                  <p>Hemel Hempstead</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Mar Risk Services Limited</h3>
                  <p>London</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Crocus Wealth Limited</h3>
                  <p>Billericay</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Travel Insured</h3>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Manor Insurance Services Limited</h3>
                  <p>Hastings</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>CRK Commercial Insurance Services Ltd</h3>
                  <p>Lutterworth</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Townley Insurance Brokers</h3>
                  <p>Taunton</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Manning Insurance Services</h3>
                  <p>Tunbridge Wells</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Crawford Davis</h3>
                  <p>Worthing</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Towergate Insurance</h3>
                  <p>Henley-On-Thames</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Manning Insurance Services</h3>
                  <p>Tunbridge Wells</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Crawford Davis</h3>
                  <p>Worthing</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Towergate Insurance</h3>
                  <p>Henley-On-Thames</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Malyon Lestrange Ins Services</h3>
                  <p>Broxbourne</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Cooke and Mason plc</h3>
                  <p>Retford</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>TLO Risk Services Limited</h3>
                  <p>Birmingham</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Malago Insurance Brokers Limited</h3>
                  <p>Bristol</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Community Broking Group plc</h3>
                  <p>Manchester</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Titan Insurance Services</h3>
                  <p>London</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Macfarlane Gray Insurance Services</h3>
                  <p>Stirling</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 btmbdr">
                  <h3>Commercial Risks UK Ltd</h3>
                  <p>London</p>
                </div>


              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="fut">
         <div className="container">
            <div className="col-lg-12">
               <div className="center-block text-center log">
                  <a href="#" className="logo1"><img src="https://yourlife-insured.co.uk/splits/over50lifeinsurance/0602YLI00-F5/dist/img/log_wht.png" alt="logowht"/></a>
               </div>
               <p className="copyright">
                  Choosing to take out a life insurance policy is a huge step and it can be daunting and scary if you don't know exactly what you are looking for. There are many types of cover available, and many different companies offering it. It is important that you understand the various options that are available to you. Life insurance is not a "one size fits all" situation, so why should you take the first plan that comes along when cheaper and/or more suitable options may be available?
               </p>
               <p className="copyright">
                  Taking out a life insurance policy is a big thing to consider for anybody, and you should weigh up your options before deciding on the plan that is right for you. Here at YourLife Insured, we make this process easier by comparing hundreds of life insurance plans from the UK's top insurers to find the most suitable and beneficial plan for you based on your specific situation. Not only will working with us inevitably save you a lot of time, but we are sure to save you money because of the insurer relationship we have.
               </p>
            </div>
         </div>
      </section>
      <ThankyouFooter />
    </div> 
    </>
    );

}
export default React.memo(Suppliers);