import React, { useState, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import TextField from '../../../UI/TextField';
import Telephone from "../../../UI/Telephone";
import Email from "../../../UI/Email";
import InputButton from "../../../UI/InputButton";
import ProgressBar from "../YLI00_F6/ProgressBar";
import PrivacyPolicy from '../PrivacyPolicy';
import SelectBox from "../../../UI/SelectBox";
import { Salutation } from "../../../../Constants/Constants";
import Otp from "../../../Pages/Otp";
import Button from "../../../UI/Button";
import { useLifeInsuranceOtp } from "../../../../Hooks/useLifeInsuranceOtp";
import { usePhoneValidation } from "../../../../Hooks/usePhoneValidation";
import { NumberValidation } from "../../../../Utility/NumberValidation";

const BasicDetails = ({
  className,
  slideChange,
  slidePreChange,
  textHeading,
  validation,
  validationMsg,
  basic_onchangehandle,
  setError,
  namevalidate,
  emailValidCheck,
  phoneValidCheck,
  reff,
  disabled,
  formSubmit,
  SetOtp,
  otp,
  otpVal,
  setOtpVal
}) => {

  const {
    getValues,
    register,
    trigger,
    ref,
    state,
    setValue,
    reset,
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {},
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: true,
  });
  const values = getValues();
  const formData = values;
  let customUuid = "";
  const query = new URLSearchParams(window.location.search);
  const query_uuid = query.get("uuid");
  const local_uuid = localStorage.getItem("uuid");
  customUuid = query_uuid ? query_uuid : local_uuid;
  const [showprivacy, setshowprivacy] = useState(false);
  const [modalBody, setModalBody] = useState('hide');
  const { sendOtp, isLoading } = useLifeInsuranceOtp();
  const [phone, setPhone] = useState('');
  const updatePhoneNumber = (newPhoneNumber) => {
    setPhone(newPhoneNumber);
  };

  const handleprivacyShow = () => {
    setshowprivacy(true);
  };

  const handleprivacyClose = () => {
    setshowprivacy(false);
  };
  const {
    phoneValidation
  } = usePhoneValidation();

  const scrollRef = useRef([]);
  const scrollToElement = (element) => {
    scrollRef.current[element]?.scrollIntoView({
      behavior: "smooth",
      block: 'center',
      inline: 'center'
    });
  };

  const OtpPage = async (e) => {
    let user_phone = '';
    let errorFlag = 0;
    user_phone = document.getElementById('txtPhone').value;
    if (
      user_phone.trim() == "" ||
      user_phone.trim().length == 0
    ) {
      errorFlag = 0;
      setError("txtPhone", {
        type: "manual",
        message: "Please Enter Phone Number",
      });
      return false;
    }
    if (user_phone.length == 11) {
      if (user_phone == "01777777777") {
        errorFlag = 1;
      }
      if (user_phone.substring(0, 2) == '07') {
        const getPhoneValidation = await phoneValidation(user_phone)
        if (
          getPhoneValidation.data.status_code === 0 ||
          getPhoneValidation.data.status === "Fail"
        ) {
          scrollToElement('txtPhone');
          setError("txtPhone", {
            type: "manual",
            message: "Please Enter Valid Working Phone Number",
          });
          document.getElementById("btn_submit_claim").disabled = false;
          errorFlag = 0;
        } else if (getPhoneValidation.data.status_code === 2) {
          scrollToElement('txtPhone');
          setError("txtPhone", {
            type: "manual",
            message: "Phone Number Already Exist",
          });
          document.getElementById("btn_submit_claim").disabled = false;
          errorFlag = 0;
        } else if (getPhoneValidation.data.status_code === 3) {
          scrollToElement('txtPhone');
          setError("txtPhone", {
            type: "manual",
            message: "Please Enter Valid Phone Number",
          });
          document.getElementById("btn_submit_claim").disabled = false;
          errorFlag = 0;
        } else {
          errorFlag = 1;
        }
      }
    }
    if (errorFlag == 1) {
      setModalBody('show');
      setPhone(user_phone);
      const response = await sendOtp(user_phone, customUuid);
      setOtpVal("d-none");

    } else {
      setError("txtPhone", {
        type: "manual",
        message: "Please Enter Phone Number",
      });
      return false;
    }

  };
  return (
    <>
      <div id="slide8" className={`col-12 top_minus_1 ${className}`}>
        <ProgressBar value="90" />
        <div className="col-lg-12 col-md-12 col-sm-12 px-3 form_cont">
          <div className="col-lg-12 col-md-12 col-sm-12 form-group">
            <label>{textHeading}</label>
            <div className="row">
              <div className="col-lg-8 offset-lg-2 col-md-12 col-sm-12 mt-1 dmy">
                <p className="text-center para"><strong>Who is this quote for?</strong></p>
                <div className="form-group" ref={(el) => (reff.current['lstSalutation'] = el)}>
                  <SelectBox
                    className="form-control"
                    OptionValue={Salutation}
                    name="lstSalutation"
                    id="lstSalutation"
                    //onChange={getValue}
                    myRef={validation({ required: "Please Select Salutation" })}
                    validationMsg={
                      validationMsg.lstSalutation && validationMsg.lstSalutation.message
                    }
                  />
                </div>
                <div className=" form-group" ref={(el) => (reff.current['txtFName'] = el)}>
                  <TextField
                    type="text"
                    className="form-control"
                    placeholder="First Name"
                    name="txtFName"
                    id="txtFName"
                    validation={validation({
                      required: "Please Enter First Name",
                      minLength: {
                        value: 3,
                        message: "Please Enter Valid First Name",
                      },
                      pattern: {
                        value: /^[a-zA-Z\s]+$/,
                        message: "Your Name is Invalid. Please Recheck",
                      },
                    })}
                    validationMsg={
                      validationMsg.txtFName && validationMsg.txtFName.message
                    }
                    onChange={basic_onchangehandle}
                    onBlur={namevalidate}
                  />
                </div>
              </div>
              <div className="col-lg-8 offset-lg-2 col-md-12 col-sm-12 mt-1 dmy">
                <div className=" form-group" ref={(el) => (reff.current['txtLName'] = el)}>
                  <TextField
                    type="text"
                    className="form-control"
                    placeholder="Surname"
                    name="txtLName"
                    id="lname"
                    validation={validation({
                      required: "Please Enter Surname",
                      minLength: {
                        value: 3,
                        message: "Please Enter Valid Surname",
                      },
                      pattern: {
                        value: /^[a-zA-Z\s]+$/,
                        message: "Your Name is Invalid. Please Recheck",
                      },
                    })}
                    validationMsg={
                      validationMsg.txtLName && validationMsg.txtLName.message
                    }
                    onChange={basic_onchangehandle}
                    onBlur={namevalidate}
                  />
                </div>
              </div>
              <div className="col-lg-8 offset-lg-2 col-md-12 col-sm-12 mt-1 dmy">
                <p className="text-center para"><strong>Where should we send your quote?</strong></p>
                <div className=" form-group" ref={(el) => (reff.current['txtPhone'] = el)}>
                  <div className="input-group-append">
                    <span className="input-group-text privacyico">
                      <Telephone
                        name="txtPhone"
                        className="form-control phone_fld"
                        placeholder="Mobile Phone"
                        maxLength="11"
                        id="txtPhone"
                        onBlur={phoneValidCheck}
                        onKeyPress={(e) => {
                          // Your existing logic for preventing non-numeric input
                          !/[0-9]+$/.test(e.key) && e.preventDefault();
                        }}
                        validation={validation({
                          pattern: {
                            value: /^[0-9]*$/i,
                            message: "Please Enter Valid Phone Number",
                          },
                          required: "Please Enter Phone Number",
                        })}
                        value={phone}
                        onChange={(e) => { setPhone(e.target.value); SetOtp('false') }}
                      ></Telephone>

                      {otp == "true" ? (
                        <Button
                          type="button"
                          className="btn btn-success verify_btn"
                          id="verified"
                          disabled={true}
                          buttonText={otp === "true" ? <>&#10003;</> : "NOT VERIFIED"}
                        >

                        </Button>
                      ) : (
                        <Button
                          type="button"
                          className="btn  verify_btn"
                          id="verify"
                          onClick={OtpPage}
                          buttonText="VERIFY"
                        >

                        </Button>
                      )}
                    </span>
                    {validationMsg.txtPhone && (
                      <span className="address1_error error_msg">
                        {validationMsg.txtPhone.message}
                      </span>
                    )}


                  </div>
                  {(!validationMsg.txtPhone && otpVal == 'd-block') && <span className={`txtOtp error_msg`} name="txtOtp">Please Verify </span>}

                </div>
              </div>

              <Otp
                key={phone}
                modalBody={modalBody}
                setModalBody={setModalBody}
                phone={phone}
                setPhone={setPhone}
                SetOtp={SetOtp}
                updatePhoneNumber={updatePhoneNumber}
              />
              <div className="col-lg-8 offset-lg-2 col-md-12 col-sm-12 mt-1 dmy">
                <div className=" form-group mb-1" ref={(el) => (reff.current['txtEmail'] = el)}>
                  <Email
                    name="txtEmail"
                    className="form-control"
                    placeholder="Email Address"
                    onBlur={emailValidCheck}
                    validation={validation({
                      required: "Please Enter Valid Email Address",
                      pattern: {
                        value:
                          /^([\w-\.]+)@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/,
                        message: "Invalid Email Address",
                      },
                    })}
                    validationMsg={
                      validationMsg.txtEmail && validationMsg.txtEmail.message
                    }
                    onChange={basic_onchangehandle}
                  >
                  </Email>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 bord text-center">
          <InputButton
            style={{ float: "none" }}
            name="basic_details_btn"
            className="next-but btn my-bt sctop1 f6 next08"
            value="Continue >>"
            btnType="button"
            onClick={formSubmit}
            disabled={disabled}
            id="btn_submit_claim"
          />
          <div className="col-12 text-center mt-1">
            <a id="back7" className="back_btn sctop" name="basic_details_btn_back" onClick={slidePreChange}><img src="/assets/YLI00_F6_5/img/arrow.png" alt="" />  Previous </a>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 text-center">
            <div className="col-lg-8 offset-lg-2 col-12 authorise text-center">
              <p>By continuing you will receive a call from an FCA authorised life insurance consultant if we feel we can help you based on the information you have provided. More details can be found in our <a className="rp_clk" data-bs-toggle="modal" data-bs-target="#prvcy" onClick={handleprivacyShow}>Privacy Policy</a>.</p>
            </div>
          </div>
          <div className="text-center safe-text">
            <img src="/assets/YLI00_F6_5/img/ico-safe.png" alt="Safe, Secure &amp; Confidential" width="12" height="15" />Safe | Secure | Privacy Protected
          </div>
        </div>
      </div>
      <PrivacyPolicy
        showstatus={showprivacy}
        hidepopup={handleprivacyClose}
      />
    </>
  );
};
export default BasicDetails;
