import React, { useContext, useState, useReducer } from "react";
import ProgressBar from "../../Layouts/YLI00_F5/ProgressBar";
import InputButton from "../../../UI/InputButton";
import { useHistory } from "react-router-dom";
import VisitorParamsContext from "../../../../Contexts/VisitorParams";
import AddressTextField from "../../../UI/AddressTextField";
import SelectBox from "../../../UI/SelectBox";
import HiddenField from "../../../UI/HiddenField";
import { usePostCodeAddress } from "../../../../Hooks/usePostCodeAddress";
import { urlParams } from "../../../../Utility/QueryString";

const initialState = {
  lookUp: true,
  nextClick: false,
  checkSelect: false,
  getDetails: [],
  getAddress: [],
  showManualAddress: "hide",
  getPostcode: "#",
  clickManualLink: 0,
}
const postcodeReducer = (state, action) => {
  switch (action.type) {
    case 'validationSucess': {
      return {
        ...state,
        lookUp: action.payload.lookup,
        nextClick: action.payload.next_click,
        getPostcode: action.payload.postcode,
        getAddress: action.payload.get_address,
        checkSelect: action.payload.check_select,
        showManualAddress: action.payload.manual_address,

      }
    }
    case 'getExtraAddressDetails': {
      return {
        ...state,
        getDetails: action.payload.get_details,
        showManualAddress: action.payload.manual_address
      }
    }
    case 'showManualDiv': {
      return {
        ...state,
        showManualAddress: "show",
        lookUp: false,
        nextClick: true,
        checkSelect: false,
        clickManualLink: action.payload.manual_click,

      }

    }
    default:
      return state;
  }
}
const PostCode = ({
  className,
  textHeading,
  validation,
  validationMsg,
  trigger,
  splitForm,
  setError,
  getValues,
  clearErrors,
  slideChange,
  slidePreChange
}) => {
  const { visitorParameters } = useContext(VisitorParamsContext);
  const [state, dispatch] = useReducer(postcodeReducer, initialState);
  const [postCodeAddress, setPostCodeAddress] = useState([]);
  const [postCodeEntry, setPostCodeEntry] = useState('0');
  const { getPostcodeAddress, getSplitPostcodeAddress } = usePostCodeAddress();
  const history = useHistory();
  const basicDetailsSubmit = async (e) => {
    const txtPostCode = await trigger("txtPostCode");
    const form = splitForm.current;
    const values = getValues();
    const formData = values;
    formData.pCode_manual = form['pCode_EntryType'].value;
    localStorage.setItem('formData', JSON.stringify(formData));
    let pid = "";
    pid = urlParams.get("pid");
    let errorFlag = 0;
    var manual_txtAddress1;
    var manual_txtTown;
    var manual_txtCountry;
    var txtAddress;
    if (txtPostCode) {
      manual_txtAddress1 = await trigger("txtAddress1");
      txtAddress = await trigger("address1");
    }
    else {
      document.getElementById("txtPostCode").focus();
      return false;
    }
    if (manual_txtAddress1) {
      manual_txtTown = await trigger("txtTown");
    }
    else {
      return false;
    }
    if (manual_txtTown) {
      manual_txtCountry = await trigger("txtCountry");
    }
    else {
      return false;
    }
    if (
      !txtPostCode ||
      !manual_txtAddress1 ||
      !manual_txtTown ||
      !manual_txtCountry
    ) {
      errorFlag = 1;
    }
    if (state.clickManualLink === 0) {
      const address1 = await trigger("address1");
      if (!address1) {
        errorFlag = 1;
      }
    }
    if (errorFlag === 0) {
      checkPostCodeEntry();
      slideChange(e);
    } else {
      return false;
    }
  };
  const checkValidation = async (e) => {
    document.getElementById("address1").classList.add('anim_ylw');
    e.preventDefault();
    const txtPostCode = await trigger("txtPostCode");
    if (txtPostCode) {
      const getValidation = await getPostcodeAddress(e.target.value);
      if (getValidation.data.status === '0') {
        setError("txtPostCode", {
          type: "manual",
          message: "Please Enter Valid Postcode",
        });
      }
      if (getValidation.data.length > 0) {
        dispatch({
          type: 'validationSucess',
          payload: {
            lookup: false,
            next_click: true,
            postcode: e.target.value,
            get_address: getValidation.data,
            check_select: true,
            manual_address: "hide",
          }
        });
      }
    }
  };
  const checkPostCodeEntry = () => {
    const addressLine1 = document.getElementById("txtAddress1").value;
    const addressLine2 = document.getElementById("txtAddress2").value;
    const town = document.getElementById("txtTown").value;
    const county = document.getElementById("txtCounty").value;
    const country = document.getElementById("txtCountry").value;

    if (postCodeAddress.length === 0 ||
      postCodeAddress.Country !== country ||
      postCodeAddress.County !== county ||
      postCodeAddress.Line1 !== addressLine1 ||
      postCodeAddress.Line2 !== addressLine2 ||
      postCodeAddress.Town !== town) {
      setPostCodeEntry('1');
    }
  };
  const HiddenFields = () => (
    <>
      <HiddenField
        name="txtAddress3"
        inputId="txtAddress3"
        inputValue={state.getDetails !== "#" ? state.getDetails.Line3 : ""}
      />
      <HiddenField
        name="txtAddress4"
        inputId="txtAddress4"
        inputValue={state.getDetails !== "#" ? state.getDetails.Line4 : ""}
      />
      <HiddenField
        name="txtDistrict"
        inputId="txtDistrict"
        inputValue={state.getDetails !== "#" ? state.getDetails.District : ""}
      />
      <HiddenField
        name="txtLocality"
        inputId="txtLocality"
        inputValue={state.getDetails.Line3 !== "#" ? state.getDetails.Locality : ""}
      />
    </>
  );
  const lookupValidation = async (e) => {
    await trigger("txtPostCode");
  }
  const getValue = async (e) => {
    e.preventDefault();
    clearErrors(["txtAddress1", "txtAddress2", "txtCounty", "txtTown", "txtCountry"]);
    const getData = await getSplitPostcodeAddress(e.target.value, state.getPostcode)
    dispatch({ type: 'getExtraAddressDetails', payload: { get_details: getData.data, manual_address: "show" } });
    setPostCodeAddress(getData.data);
  };
  return (
    <>
      <div id="slide7" className={`col-12 top_minus_1 ${className}`}>
        <ProgressBar value="80" />

        <HiddenField
          name="pCode_EntryType"
          inputId="pCode_EntryType"
          inputValue={postCodeEntry}
        />

        <div className="col-lg-12 col-md-12 col-sm-12 px-3 form_cont">
          <div className="col-lg-12 col-md-12 col-sm-12 form-group">
            <label>{textHeading}</label>
            <div className="row">
              <div className="col-lg-8 offset-lg-2 col-md-12 col-sm-12 mt-1 dmy">
                <div className="col-12">
                  <div className="form-group">
                    <div className="input-group post">
                      <AddressTextField
                        type="text"
                        placeholder="Your Postcode"
                        name="txtPostCode"
                        id="txtPostCode"
                        className="form-control mob-wd"
                        dataId="txtPostCode"
                        onBlur={checkValidation}
                        onChange={() => {
                          dispatch({
                            type: 'validationSucess',
                            payload: {
                              lookup: true,
                              next_click: false,
                              check_select: false,
                              manual_address: "hide",
                              get_address: [],
                              manual_click: 1
                            }
                          })
                        }}
                        autoComplete="off"
                        validation={validation({
                          required: "Please Enter Postcode",
                          pattern: {
                            value: /^(([A-Z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2})|([A-Z]{1,2}[0-9][A-Z] ?[0-9][A-Z]{2,3}))$/i,
                            message: "Please Enter a Valid Postcode",
                          },
                          minLength: {
                            value: 5,
                            message: "Please Enter a Valid Postcode",
                          },
                        })}
                        validationMsg={
                          validationMsg.txtPostCode && validationMsg.txtPostCode.message
                        }
                      />
                    </div>
                  </div>
                  
                  <div
                    className={`form-group`}
                    id="currentAddressCollapse"
                    style={state.checkSelect ? { display: "block" } : { display: "none" }}
                  >
                    <div className="input-group post">
                      <SelectBox
                        className="form-control"
                        OptionValue={state.getAddress}
                        name="address1"
                        id="address1"
                        onChange={getValue}
                        myRef={validation({ required: "Please Select Address" })}
                        validationMsg={
                          validationMsg.address1 && validationMsg.address1.message
                        }
                      />
                    </div>
                  </div>
                  <div
                    className="col-12 text-center"
                    style={
                      state.getDetails.length === 0 ? { display: "block" } : { display: "none" }
                    }
                  >
                    <a
                      className="pfrp d-none"
                      id="manual-entry-btn"
                      onClick={() => { dispatch({ type: 'showManualDiv', payload: { manual_address: "show", manual_click: 1 } }) }}
                      children="[ Having Issues? Click Here to Enter Manually ]"
                    ></a>
                  </div>
                  <div className={`slide8_add row ${state.showManualAddress}`}>
                    <div className={`input-group post`}>
                      <AddressTextField
                        type="text"
                        placeholder="Address Line 1 *"
                        readonly="readonly"
                        name="txtAddress1"
                        id="txtAddress1"
                        className="form-control  hero-input"
                        dataId="txtAddress1"
                        autoComplete="off"
                        validation={validation({
                          required: "Please Enter Address Line 1",
                        })}
                        validationMsg={
                          validationMsg.txtAddress1 &&
                          validationMsg.txtAddress1.message
                        }
                        value={state.getDetails.Line1}
                      />
                      <i
                        className="validate validate_success"
                        aria-hidden="true"
                        style={{ display: "none" }}
                      ></i>
                    </div>
                    <div className={`input-group post`}>
                      <AddressTextField
                        type="text"
                        readonly="readonly"
                        placeholder="Address Line 2"
                        name="txtAddress2"
                        id="txtAddress2"
                        className="form-control  hero-input"
                        dataId="txtAddress2"
                        autoComplete="off"
                        validation={validation()}
                        validationMsg=""
                        value={state.getDetails.Line2}
                      />
                      <i
                        className="validate validate_success"
                        aria-hidden="true"
                        style={{ display: "none" }}
                      ></i>
                    </div>
                    <div className={`input-group post`}>
                      <AddressTextField
                        type="text"
                        readonly="readonly"
                        placeholder="County"
                        name="txtCounty"
                        id="txtCounty"
                        className="form-control  hero-input"
                        dataId="txtCounty"
                        autoComplete="off"
                        validation={validation()}
                        validationMsg=""
                        value={state.getDetails.County}
                      />
                      <i
                        className="validate validate_success"
                        aria-hidden="true"
                        style={{ display: "none" }}
                      ></i>
                    </div>
                    <div className={`input-group post`}>
                      <AddressTextField
                        type="text"
                        readonly="readonly"
                        placeholder="Town *"
                        name="txtTown"
                        id="txtTown"
                        className="form-control  hero-input"
                        dataId="txtTown"
                        autoComplete="off"
                        validation={validation({ required: "Please Enter Town" })}
                        validationMsg={
                          validationMsg.txtTown && validationMsg.txtTown.message
                        }
                        value={state.getDetails.Town}
                      />
                      <i
                        className="validate validate_success"
                        aria-hidden="true"
                        style={{ display: "none" }}
                      ></i>
                    </div>
                    <div className={`input-group post`}>
                      <AddressTextField
                        type="text"
                        readonly="readonly"
                        placeholder="Country *"
                        name="txtCountry"
                        id="txtCountry"
                        className="form-control  hero-input"
                        dataId="txtCountry"
                        autoComplete="off"
                        validation={validation({ required: "Please Enter Country" })}
                        validationMsg={
                          validationMsg.txtCountry && validationMsg.txtCountry.message
                        }
                        value={state.getDetails.Country}
                      />
                      <i
                        className="validate validate_success"
                        aria-hidden="true"
                        style={{ display: "none" }}
                      ></i>
                    </div>
                    {Object.keys(state.getDetails).length > 0 ? <HiddenFields /> : null}
                  </div>
                </div>
              </div>

              <span id="fullDOB_err" className="error_msg error"></span>
            </div>
          </div>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 bord text-center">

        <div
            className="col-lg-12 col-12  p-0 text-right slide8_lkup"
            style={state.lookUp ? { display: "block" } : { display: "none" }}
          >
            <div style={{ width: "100%" }} className="main_1">
              <InputButton
                style={{ float: "none" }}
                name="next"
                className="next-but btn my-bts flasher2 sctop1"
                value="Lookup Address"
                btnType="button"
                onClick={lookupValidation}
              />
            </div>
          </div>

          <div
            className="col-lg-12 col-12 text-center p-0"
            style={state.nextClick ? { display: "block" } : { display: "none" }}
          >
            <div style={{ width: "100%" }} className="main_1">
              <InputButton
                name="post_code_btn"
                className="next-but btn my-bts flasher2 sctop1 next07"
                id=""
                value="Continue >>"
                btnType="button"
                onClick={basicDetailsSubmit}
                style={{ float: "none" }}
              />
            </div>
          </div>
          <div className="col-12 text-center mt-1">
            <a id="back6" className="back_btn sctop1" name="post_code_btn_back" onClick={slidePreChange}><img src="/assets/YLI00_F5/img/arrow.png" alt="" />  Previous </a>
          </div>
          <div className="text-center safe-text"> 
            <img src="/assets/YLI00_F5/img/ico-safe.png" alt="Safe, Secure &amp; Confidential" width="12" height="15" />Safe | Secure | Privacy Protected
          </div>
        </div>

      </div>
    </>
  );
};

export default PostCode;
