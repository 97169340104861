import React, { useContext, useEffect, useRef, useState } from "react";
import "../../assets/YLI00_F6_6/css/Otp.scss"
import { useLifeInsuranceOtp } from "../../Hooks/useLifeInsuranceOtp";
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import VisitorParamsContext  from "../../Contexts/VisitorParams";

const Otp = ({modalBody,setModalBody,phone,SetOtp,setPhone,updatePhoneNumber}) => {
  const {
    getValues,
    register,
    trigger,
    validation,
    ref,
    state,
    setValue,
    reset,
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {},
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: true,
  });

  useEffect(() => {
    if (modalBody === "show") {
      // Clear the OTP input fields when the modal becomes visible
      setValue('otp_1', '');
      setValue('otp_2', '');
      setValue('otp_3', '');
      setValue('otp_4', '');
      setValue('otp_5', '');
      setValue('otp_6', '');
    }
  }, [modalBody]);

  const handleSubmit = async (e) => {
   
    e.preventDefault();
    const form = otpForm.current;
    const values = getValues();
    const formData = values;
    let customUuid = "";
    const query = new URLSearchParams(window.location.search);
    const query_uuid = query.get("uuid");
    const local_uuid = localStorage.getItem("uuid");
    customUuid = query_uuid ? query_uuid : local_uuid;
    try {
      const response = await saveOtp(customUuid, formData);
      if (response.data.msg === "Success") {
        setErrMsg("d-none");
        // const response = await saveMilestone(customUuid);
        setModalBody('hide');
        SetOtp("true");
      
        updatePhoneNumber(formData.phone);

      } else {
        setMsgSend("d-none");
        setErrMsg("show");
        setValue("otp_1", "");
        setValue("otp_2", "");
        setValue("otp_3", "");
        setValue("otp_4", "");
        setValue("otp_5", "");
        setValue("otp_6", "");
      }
    } catch (e) {
      console.warn(e);
    }
  };

  const { sendOtp, saveOtp, regenerateOtp, saveMilestone, isLoading } =
  useLifeInsuranceOtp();
  const otpForm = useRef(null);
  const [errMsg, setErrMsg] = useState("d-none");
  const [msgSend, setMsgSend] = useState("d-none");
  const [reSendto, setReSendto] = useState(null);
  const [invalidError, setInvalidError] = useState(false);
  const [phoneBtn, setPhoneBtn] = useState(false);
  const [phonev, setphonev] = useState(phone);
  const [phoneotp, setphoneotp] = useState(phone);
  const [btnName, setBtnName] = useState("");






  const handleBoxChange = (e) => {
    e.preventDefault();
    const { maxLength, value, name } = e.target;
    const [fieldName, fieldIndex] = name.split("_");
    let fieldIntIndex = parseInt(fieldIndex, 10);
    if (value.length >= maxLength) {
      if (fieldIntIndex < 6) {
        const nextfield = document.querySelector(
          `input[name=otp_${fieldIntIndex + 1}]`
        );
        if (nextfield !== null) {
          nextfield.focus();
        }
      }
    }
  };

  const handleClick = (e) => {
    // e.preventDefault();
    let name = e.target.name;
    setValue(name, "");
  };

  const validNumber = (evt) => {
    evt = evt ? evt : window.event;
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      // evt.preventDefault();
      return false;
    } else {
      return true;
    }
  };

  const [resendCounter, setResendCounter] = useState(0);
  const MAX_RESEND_LIMIT = 5;

  console.log(MAX_RESEND_LIMIT);

  const resendOtp = async (e) => {
    e.preventDefault();
    let sendTo = "";
    let otpMode = "";
    if (e.target.name == "btnphone") {
      setBtnName("btnphone");
    }
    const values = getValues();
    const formData = values;
    let value = formData.phone;
    setphoneotp(value)
    if (e.target.name == "btnphone") {
      setPhoneBtn(true);
      sendTo = reSendto == null ? value : reSendto;
      otpMode = "Phone";
    }
    let customUuid = "";
    const query = new URLSearchParams(window.location.search);
    const query_uuid = query.get("uuid");
    const local_uuid = localStorage.getItem("uuid");
    customUuid = query_uuid ? query_uuid : local_uuid;
    try {
      const response = await regenerateOtp(
        sendTo,
        customUuid,
        otpMode,
        null
      );
      setTimeout(() => {
        (response.data.resend == 1 && phoneBtn) ||
        (response.data.resend == 1 && otpMode == "Phone")
          ? setPhoneBtn(true)
          : setPhoneBtn(false);
          setBtnName("");
      }, 3000);
      if (response.data.status === "success") {
        setErrMsg("d-none");
        setMsgSend("show");
        setReSendto(null);
        setInvalidError(false);
        setResendCounter((prevCounter) => prevCounter + 1);

        if (resendCounter === MAX_RESEND_LIMIT) {
          setPhoneBtn(false); 
        }
      } else if (response.data.status == "Invalid") {
        setBtnName("");
        setInvalidError(true);
      }
    } catch (e) {
      console.warn(e);
    }
  };
  return (
    <>
  {/* <p className="text-center">
    {" "}
    <button
      className="btn btn-success"
      data-bs-toggle="modal"
      data-bs-target="#varification_modal"
    >
      {" "}
      Demo{" "}
    </button>
  </p> */}
  <div className='otp'>
  <div
    className={`modal fade ${modalBody}`}
    id="varification_modal"
    tabIndex={-1}
    aria-labelledby="exampleModalLabel"
    aria-hidden=""
  >
    <div className="modal-dialog modal-lg m-auto mt-3 text-center">
      <div className="modal-content">
        <div className="modal-body">
          <div className="container">
            <fieldset className="border form_wrap  mt-lg-0 ">
              <legend>We Take Security Very Seriously</legend>
              <div className="row  ">
                <div className="col-lg-12">
                  <h3 className="title mb-lg-0 mb-md-0 mb-sm-0">
                    Type in the code we texted you to verify your account.
                  </h3>

                  <div className="otp_box">
                    <p className="para1">
                      {" "}
                      We just texted a verification code to <b> {phoneotp} </b>
                    </p>
                    <div className="box_verifi mb-3">
                      <input
                        type="text"
                        id="otp_1"
                        name="otp_1"
                        maxLength="1"
                        className="efct_filed"
                        onChange={handleBoxChange}
                        onKeyPress={validNumber}
                        onClick={handleClick} 
                        ref={register()}
                      />
                      <input
                        type="text"
                        id="otp_2"
                        name="otp_2"
                        maxLength="1"
                        className="efct_filed"
                        onChange={handleBoxChange}
                        onKeyPress={validNumber}
                        onClick={handleClick}
                        ref={register()}
                      />
                      <input
                        type="text"
                        id="otp_3"
                        name="otp_3"
                        maxLength="1"
                        className="efct_filed"
                        onChange={handleBoxChange}
                        onKeyPress={validNumber}
                        onClick={handleClick}
                        ref={register()}
                      />
                      <div> - </div>
                      <input
                        type="text"
                        id="otp_4"
                        name="otp_4"
                        maxLength="1"
                        className="efct_filed"
                        onChange={handleBoxChange}
                        onKeyPress={validNumber}
                        onClick={handleClick}
                        ref={register()}
                      />
                      <input
                        type="text"
                        id="otp_5"
                        name="otp_5"
                        maxLength="1"
                        className="efct_filed"
                        onChange={handleBoxChange}
                        onKeyPress={validNumber}
                        onClick={handleClick}
                        ref={register()}
                      />
                      <input
                        type="text"
                        id="otp_6"
                        name="otp_6"
                        maxLength="1"
                        className="efct_filed"
                        onChange={handleBoxChange}
                        onKeyPress={validNumber}
                        onClick={handleClick}
                        ref={register()}
                      />
                    </div>
                    <div className={`text-danger box_verifi mb-3 ${errMsg}`}>
                        <b>Invalid OTP.</b>
                      </div>
                    <div className="d-lg-flex mb-lg-4 mb-md-4">
                      <div>
                        <button
                         type="button"
                          className="com_bnt"
                          id="sendVari"
                          data-bs-toggle="modal"
                          onClick={handleSubmit}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                    <div
                        className={`text-success box_verifi mb-3 ${msgSend}`}
                      >
                        <b>New OTP sent. Please check.</b>
                      </div>
                  </div>
                  <fieldset className="border form_wrap mt-2 mt-sm-4  mb-lg-3 mt-md-4 mt-lg-4 resend_area pb-4">
                    <legend className="title_sub">
                      Not Received the Verification Code?
                    </legend>
                    <div className="row mb-4 mb-lg-2 mb-md-2 plt_space">
                      <p style={{ maxWidth: 608 }} className="sub_text">
                        {" "}
                        Please check the number and email address provided (and
                        edit if necessary) , we will resend a verification code
                        via <b> SMS</b>.
                      </p>
                      <div className="col-lg-7 px-2 mt-0">
                        <input
                          type="text"
                          name="phone"
                          defaultValue={phone}
                          maxLength="11"
                          className="form-control"
                          ref={register()}
                        />
                      </div>
                      <div className="col-lg-5 ps-lg-0 mt-lg-0 mt-md-3 mt-3">
                        <button
                          type= "button"
                          className="com_bnt"
                          data-bs-toggle="modal"
                          id="btnphone"
                          data-otpmode="phone"
                          name="btnphone"
                          onClick={resendOtp}
                        >
                           {btnName == "btnphone"
                              ? "Sent"
                              : "Resend code via SMS"}
                        </button>
                      </div>
                    </div>
                    <div className="col-lg-7">
                        {invalidError && (
                          <p className="error_msg">Invalid Phone Number</p>
                        )}
                      </div>
                  </fieldset>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
    </>
  )
}
export default Otp;
