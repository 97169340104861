import React, { useEffect, useState, useRef, useReducer, useContext } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import QueryStringContext from "../../Contexts/QueryString";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import { useDataIngestion } from "../../Hooks/useDataIngestion";
import BasicQuestionnaireOne from "../Includes/Layouts/YLI00_F6_5/BasicQuestionnaireOne";
import BasicQuestionnaireTwo from "../Includes/Layouts/YLI00_F6_5/BasicQuestionnaireTwo";
import BasicQuestionnaireThree from "../Includes/Layouts/YLI00_F6_5/BasicQuestionnaireThree";
import BasicDetails from "../Includes/Layouts/YLI00_F6_5/BasicDetails";
import Month from "../Includes/Layouts/YLI00_F6_5/Month";
import Day from "../Includes/Layouts/YLI00_F6_5/Day";
import Year from "../Includes/Layouts/YLI00_F6_5/Year";
import PostCode from "../Includes/Layouts/YLI00_F6_5/PostCode";
import AdditionalDetails from "../Includes/Layouts/YLI00_F6_5/AdditionalDetails";
import { useEmailValidation } from "../../Hooks/useEmailValidation";
import { usePhoneValidation } from "../../Hooks/usePhoneValidation";
import BasicQuestionnaireFour from "../Includes/Layouts/YLI00_F6_5/BasicQuestionnaireFour";
import BasicQuestionnaireFive from "../Includes/Layouts/YLI00_F6_5/BasicQuestionnaireFive";
import BasicQuestionnaireSix from "../Includes/Layouts/YLI00_F6_5/BasicQuestionnaireSix";
import GTMDataLayer from "../../Utility/GTMDataLayer";



const FormYLI00_F6_5 = () => {
  const history = useHistory();
  const field = "pid";
  const scrollfrmv2div = useRef(null);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const {
    saveDataIngestion,
    isLoading
  } = useDataIngestion();

  const {
    visitorParameters
  } = useContext(VisitorParamsContext);

  const {
    queryString
  } = useContext(QueryStringContext);

  const initialState = {
    showSlide1: "show",
    showSlide2: "hide",
    showSlide3: "hide",
    showSlide4: "hide",
    showSlide5: "hide",
    showSlide6: "hide",
    showSlide7: "hide",
    showSlide8: "hide",
    showSlide9: "hide",
    showSlide10: "hide",
    showSlide11: "hide",
    showSlide12: "hide"
  };

  const [lookingForInsuranceList, setLookingForInsuranceList] = useState(null);
  const [preferredTimeForCall, setPreferredTimeForCall] = useState(null);
  const [customCoverTerm,setCustomCoverTerm] = useState('above_50');
  const [buttonValue,setButtonValue] = useState("Get My Free Quote >>");
  const [progressVal,setProgressVal] = useState(95);

  const FormReducer = (state, action) => {

    switch (action.type) {
      case "showSlide": {
        if (action.payload.clickedSlide.slide === "bornBefore") {
          return {
            ...state,
            showSlide1: "hide",
            showSlide2: "show",
          };
        } else if (action.payload.clickedSlide.slide === "gender") {
          return {
            ...state,
            showSlide2: "hide",
            showSlide3: "show",
          };
        } else if (action.payload.clickedSlide.slide === "lookingForInsurance_btn") {
          return {
            ...state,
            showSlide3: "hide",
            showSlide4: "show",
          };
        } else if (action.payload.clickedSlide.slide === "DobMonth") {
          return {
            ...state,
            showSlide4: "hide",
            showSlide5: "show",
          };
        } else if (action.payload.clickedSlide.slide === "DobDay") {
          return {
            ...state,
            showSlide5: "hide",
            showSlide6: "show",
          };
        }
        else if (action.payload.clickedSlide.slide === "DobYear") {
          console.log(state);
          return {
            ...state,
            showSlide6: "hide",
            showSlide7: "show",
          };
        }
        // else if (action.payload.clickedSlide.slide === "DobYearSplit") {
        //   console.log(state);
        //   return {
        //     ...state,
        //     showSlide6: "hide",
        //     showSlide10: "show",
        //   };
        // }
        else if (action.payload.clickedSlide.slide === "submit_form") {
          console.log(state);
          return {
            ...state,
            showSlide9: "hide",
            showSlide10: "show",
          };
        }
        else if (action.payload.clickedSlide.slide === "smoker") {
          console.log(state);
          return {
            ...state,
            showSlide10: "hide",
            showSlide11: "show",
          };
        }
        else if (action.payload.clickedSlide.slide === "amount_cover") {
          return {
            ...state,
            showSlide11: "hide",
            showSlide12: "show",
          };
        }
        else if (action.payload.clickedSlide.slide === "cover_term") {
          setCustomCoverTerm('below_50');
          return {
            ...state,
            showSlide12: "hide",
            showSlide9: "show",
          };
        }
        else if (action.payload.clickedSlide.slide === "post_code_btn") {
          return {
            ...state,
            showSlide7: "hide",
            showSlide8: "show",
          };
        }
        else if (action.payload.clickedSlide.slide === "basic_details_btn") {
          return {
            ...state,
            showSlide8: "hide",
            showSlide9: "show",
          };
        }
        else if (action.payload.clickedSlide.slide === "basic_details_btn_split") {
          return {
            ...state,
            showSlide8: "hide",
            showSlide10: "show",
          };
        }
        else {
          return {
            ...state,
          };
        }
      }
      case "showPreSlide": {
        if (action.payload.clickedPreSlide.slide === "gender_back") {
          return {
            ...state,
            showSlide2: "hide",
            showSlide1: "show",
          };
        } else if (action.payload.clickedPreSlide.slide === "lookingForInsurance_btn_back") {
          return {
            ...state,
            showSlide3: "hide",
            showSlide2: "show",
          };
        } else if (action.payload.clickedPreSlide.slide === "month_back") {
          return {
            ...state,
            showSlide4: "hide",
            showSlide3: "show",
          };
        } else if (action.payload.clickedPreSlide.slide === "day_back") {
          return {
            ...state,
            showSlide5: "hide",
            showSlide4: "show",
          };
        }
        else if (action.payload.clickedPreSlide.slide === "year_back") {
          return {
            ...state,
            showSlide6: "hide",
            showSlide5: "show",
          };
        }
        else if (action.payload.clickedPreSlide.slide === "post_code_btn_back") {
          return {
            ...state,
            showSlide7: "hide",
            showSlide6: "show",
          };
        }
        else if (action.payload.clickedPreSlide.slide === "cover_term") {
          return {
            ...state,
            showSlide7: "hide",
            showSlide12: "show",
          };
        }
        else if (action.payload.clickedPreSlide.slide === "cover_term_back") {
          return {
            ...state,
            showSlide12: "hide",
            showSlide11: "show",
          };
        }
        else if (action.payload.clickedPreSlide.slide === "smoker_back") {
          return {
            ...state,
            showSlide10: "hide",
            showSlide8: "show",
          };
        }
        else if (action.payload.clickedPreSlide.slide === "amount_cover_back") {
          return {
            ...state,
            showSlide11: "hide",
            showSlide10: "show",
          };
        }
        else if (action.payload.clickedPreSlide.slide === "basic_details_btn_back") {
          return {
            ...state,
            showSlide8: "hide",
            showSlide7: "show",
          };
        }
        else if (action.payload.clickedPreSlide.slide === "submit_form_back") {
          return {
            ...state,
            showSlide9: "hide",
            showSlide8: "show",
          };
        }
        else if (action.payload.clickedPreSlide.slide === "submit_form_back_split") {
          return {
            ...state,
            showSlide9: "hide",
            showSlide12: "show",
          };
        }
        else {
          return {
            ...state,
          };
        }
      }
      default: {

      }
    }
  };

  const splitForm = useRef(null);
  const [state, dispatch] = useReducer(FormReducer, initialState);
  const [clickedSlide, setClickedSlide] = useState([]);
  const [clickedPreSlide, setClickedPreSlide] = useState([]);
  const {
    register,
    errors,
    trigger,
    setError,
    clearErrors,
    getValues,
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {},
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const slideChange = (e) => {
    console.log(e.target.name);
    gtmDataLayer(e.target.name);
    window.scrollTo({
      top: scrollfrmv2div.current.offsetTop,
      left: 0,
      behavior: 'smooth'
    });
    const values = getValues();
    const form = splitForm.current;
    const formData = values;
    let dobYear = formData.DobYear;
    let currretYear = (new Date().getFullYear());
    let yearDifference = currretYear-dobYear;
    console.log(formData);
    if(e.target.name == 'dobYear'){
    if(yearDifference > 60){
      formData.smoker = '';
      formData.amount_cover='';
      formData.cover_term = '';
     }
    }
    setClickedSlide({ slide: e.target.name });
  };
  useEffect(() => {
    if (clickedSlide) {
      dispatch({ type: "showSlide", payload: { clickedSlide } });
    }
  }, [clickedSlide]);

  const slidePreChange = (e) => {
    window.scrollTo({
      top: scrollfrmv2div.current.offsetTop,
      left: 0,
      behavior: 'smooth'
    });
    if(e.target.name == 'submit_form_back'){
    const values = getValues();
    const form = splitForm.current;
    const formData = values;
    if(formData.DobYear != ''){
      let dobYear = formData.DobYear;
      let currretYear = (new Date().getFullYear());
      let yearDifference = currretYear-dobYear;
      if(yearDifference < 60){
        setClickedPreSlide({ slide: 'submit_form_back_split' });
      } else{
        setClickedPreSlide({ slide: e.target.name });
      }
    }
  }else{
    setClickedPreSlide({ slide: e.target.name });
  }


  };

  useEffect(() => {
    if (clickedPreSlide) {
      dispatch({ type: "showPreSlide", payload: { clickedPreSlide } });
    }
  }, [clickedPreSlide]);

  const arrMonths = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  const [selectedMonth, setSelectedMonth] = useState(null);
  const [maxDays, setmaxDays] = useState(31);
  const [isLeapYear, setLeapYear] = useState(false);

  const handleSelectedMonth = (month) => {
    setSelectedMonth(month);
    let monthIndex = arrMonths.indexOf(month);
    let _maxDays = new Date(2008, monthIndex + 1, 0).getDate();
    setmaxDays(_maxDays)
  };

  const handleSelectedDay = (day) => {
    if (selectedMonth == 'February' && day == 29) {
      setLeapYear(true);
    }
  };

  const basic_onchangehandle = async (e) => {
    document.getElementById("btn_submit_claim").disabled = false;
    trigger(e.target.name);
  };

  const {
    emailValidation
  } = useEmailValidation();
  const {
    phoneValidation
  } = usePhoneValidation();

  const namevalidate = async (e) => {
    if (e.target.value.trim() == "" || e.target.value.trim().length == 0)
      setError(e.target.name, {
        type: "manual",
        message: "Your Name is Invalid. Please Recheck",
      });
    return false;
  };

  const [emailCheck, setEmailCheck] = useState('hide');
  const emailValidCheck = async () => {
    const values = getValues();
    const email = values['txtEmail'];
    if (email !== "") {
      if (/^([\w-\.]+)@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/.test(email)) {
        setEmailCheck('show');
        const getEmailValidation = await emailValidation(email);
        if (getEmailValidation.data.status_code === 0) {
          scrollToElement('txtEmail');
          setError("txtEmail", {
            type: "manual",
            message: "Email Already Exist",
          });
          setEmailCheck('hide');
          document.getElementById("btn_submit_claim").disabled = false;
          return 0;
        } else if (getEmailValidation.data.status_code === 2) {
          scrollToElement('txtEmail');
          setError("txtEmail", {
            type: "manual",
            message: "Email is Invalid",
          });
          setEmailCheck('hide');
          document.getElementById("btn_submit_claim").disabled = false;
          return 0;
        } else {

          setEmailCheck('hide');
          return 1;
        }
      } else {
        scrollToElement('txtEmail');
        setError("txtEmail", {
          type: "manual",
          message: "Invalid Email Address",
        });
        document.getElementById("btn_submit_claim").disabled = false;
        return 0;
      }
    } else {
      document.getElementById("btn_submit_claim").disabled = false;
      trigger("txtEmail");
      return 0;
    }
  };

  const phoneValidCheck = async () => {
    const values = getValues();
    const phone = values['txtPhone'];
    if (phone !== "") {
      const getPhoneValidation = await phoneValidation(phone)
      if (
        getPhoneValidation.data.status_code === 0 ||
        getPhoneValidation.data.status === "Fail"
      ) {
        scrollToElement('txtPhone');
        setError("txtPhone", {
          type: "manual",
          message: "Please Enter Valid Working Phone Number",
        });
        document.getElementById("btn_submit_claim").disabled = false;
        return 0;
      } else if (getPhoneValidation.data.status_code === 2) {
        scrollToElement('txtPhone');
        setError("txtPhone", {
          type: "manual",
          message: "Phone Number Already Exist",
        });
        document.getElementById("btn_submit_claim").disabled = false;
        return 0;
      } else if (getPhoneValidation.data.status_code === 3) {
        scrollToElement('txtPhone');
        setError("txtPhone", {
          type: "manual",
          message: "Please Enter Valid Phone Number",
        });
        document.getElementById("btn_submit_claim").disabled = false;
        return 0;
      } else {
        return 1;
      }
    }
  };

  const alterPhoneValidCheck = async () => {
    const values = getValues();
    const phone = values['txtAlterPhone'];
    if (phone !== "") {
      const getPhoneValidation = await phoneValidation(phone)
      if (
        getPhoneValidation.data.status_code === 0 ||
        getPhoneValidation.data.status === "Fail"
      ) {
        scrollToElement('txtAlterPhone');
        setError("txtAlterPhone", {
          type: "manual",
          message: "Please Enter Valid Working Phone Number",
        });
        document.getElementById("btn_submit_claim").disabled = false;
        return 0;
      } else if (getPhoneValidation.data.status_code === 2) {
        scrollToElement('txtAlterPhone');
        setError("txtAlterPhone", {
          type: "manual",
          message: "Phone Number Already Exist",
        });
        document.getElementById("btn_submit_claim").disabled = false;
        return 0;
      } else if (getPhoneValidation.data.status_code === 3) {
        scrollToElement('txtAlterPhone');
        setError("txtAlterPhone", {
          type: "manual",
          message: "Please Enter Valid Phone Number",
        });
        document.getElementById("btn_submit_claim").disabled = false;
        return 0;
      } else {
        return 1;
      }
    }
  };

  const scrollRef = useRef([]);
  const scrollToElement = (element) => {
    scrollRef.current[element]?.scrollIntoView({
      behavior: "smooth",
      block: 'center',
      inline: 'center'
    });
  };

  const addBasicDetails = async () => {
    let errorFlag = 0;
    const values = getValues();

    const txtEmail = values["txtEmail"];
    const txtFName = values["txtFName"];
    const txtLName = values["txtLName"];
    const txtPhone = values["txtPhone"];

    let txtEmailResult;
    let txtFNameResult;
    let txtLNameResult;
    let txtPhoneResult;
    let txtSalutation;


     //salutation
     txtSalutation = await trigger("lstSalutation");
     if(!txtSalutation){
      scrollToElement('lstSalutation');
      return false;
     }
      // First Name
    txtFNameResult = await trigger("txtFName");
    if (txtFNameResult) {
      if (
        txtFName.trim() == "" ||
        txtFName.trim().length == 0 ||
        txtFName.trim().length < 3
      ) {
        scrollToElement('txtFName');
        setError("txtFName", {
          type: "manual",
          message: "Your Name is Invalid. Please Recheck",
        });
        return false;
      } else {
        localStorage.setItem('first_name', txtFName);
        txtLNameResult = await trigger("txtLName");
      }
    } else {
      scrollToElement('txtFName');
      return false;
    }

    // Lastname
    if (txtLNameResult) {
      if (
        txtLName.trim() == "" ||
        txtLName.trim().length == 0 ||
        txtLName.trim().length < 2
      ) {
        scrollToElement('txtLName');
        setError("txtLName", {
          type: "manual",
          message: "Your Name is Invalid. Please Recheck",
        });
        return false;
      } else {
        localStorage.setItem('last_name', txtLName);
        txtPhoneResult = await trigger("txtPhone");
      }
    } else {
      scrollToElement('txtLName');
      return false;
    }

    if (txtPhoneResult) {
      if (
        txtPhone.trim() == "" ||
        txtPhone.trim().length == 0
      ) {
        scrollToElement('txtPhone');
        return false;
      } else {
        txtEmailResult = await trigger("txtEmail");
      }
    } else {
      scrollToElement('txtPhone');
      return false;
    }
    // Email and Phone
    if (txtEmailResult) {
      if (
        txtEmail.trim() == "" ||
        txtEmail.trim().length == 0
      ) {
        scrollToElement('txtEmail');
        return false;
      }
    } else {
      scrollToElement('txtEmail');
      return false;
    }

    if (txtEmail && txtPhone) {
      if ((await emailValidCheck()) === 1 && (await phoneValidCheck()) === 1) {
        errorFlag = 0;
      } else {
        return false;
      }
    }

    setBtnDisabled(true);

    if (errorFlag == 0) {
      gtmDataLayer("basic_details");
      window.scrollTo({
        top: scrollfrmv2div.current.offsetTop,
        left: 0,
        behavior: 'smooth'
      });
        const values = getValues();
        const form = splitForm.current;
        const formData = values;
        let dobYear = formData.DobYear;
        let currretYear = (new Date().getFullYear());
        let yearDifference = currretYear-dobYear;
        if(yearDifference < 60){
          setProgressVal(99);
          //setButtonValue('Continue >>');
          setClickedSlide({ slide: 'basic_details_btn_split' });
        } else{
       //   setButtonValue('Get My Free Quote >>');
       setClickedSlide({ slide: 'basic_details_btn' });
        }
     formSubmit();
      setBtnDisabled(false);
    }
  };
   const finalSubmit = async(e) => {
     let coverTerm = await trigger("cover_term");
     if(coverTerm){
      formSubmit();
     }else {
      setError("cover_term", {
        type: "manual",
        message: "Please Select Cover Term",
      });
      return false;
     }
   }

  const formSubmit = async () => {
    const values = getValues();
    const form = splitForm.current;
    const formData = values;

    setBtnDisabled(true);

    formData.lookingForInsurance  = lookingForInsuranceList;
    formData.preferredTimeForCall = preferredTimeForCall;

    if (formData.address1 !== "") {
      const txtAddress3 = form["txtAddress3"].value;
      const txtCountry = form["txtCountry"].value;
      const txtCounty = form["txtCounty"].value;

      formData.txtAddress3 = txtAddress3;
      formData.txtCounty = txtCounty;
      formData.txtCountry = txtCountry;

      const txtAddress4 = form["txtAddress4"].value;
      const txtDistrict = form["txtDistrict"].value;
      const txtLocality = form["txtLocality"].value;
      formData.txtAddress3 = txtAddress3;
      formData.txtAddress4 = txtAddress4;
      formData.txtDistrict = txtDistrict;
      formData.txtLocality = txtLocality;
     let currretYear = (new Date().getFullYear());
     let yearDifference = currretYear-formData.DobYear;
      if(yearDifference > 60){
        formData.amount_cover ='';
        formData.cover_term ='';
        formData.smoker ='';
       }
    }
    //console.log(formData);
    const formSUbmitResult = await saveDataIngestion(
      visitorParameters.visitor_parameters,
      formData,
      'split_form_submit',
      'over50lifeinsurance/0602YLI00-F6.5',
      queryString,
      visitorParameters.data
    )

    if (formSUbmitResult.data.status === "Success") {
      console.log(formSUbmitResult.data.status);
      // if (queryString != null) {
      //   if (queryString.indexOf("&" + field + "=") !== -1) {
      //     history.push("/fbpixel"); // fb fixel firing
      //   } else {
      //     history.push(
      //       "/thankyou?uuid=" + visitorParameters.visitor_parameters.uuid
      //     );
      //   }
      // } else {
      //   history.push(
      //     "/thankyou?uuid=" + visitorParameters.visitor_parameters.uuid
      //   );
      // }
    }
  };
const questionSubmit = async() => {
  const values = getValues();
  const form = splitForm.current;
  const formData = values;
  formData.preferredTimeForCall = preferredTimeForCall;
  gtmDataLayer("preferred_time");
  const result = await saveDataIngestion(
    visitorParameters.visitor_parameters,
    formData,
    'question_form_submit',
    'over50lifeinsurance/0602YLI00-F6.5',
    queryString,
    visitorParameters.data
  )
  if (result.data.status === "Success") {
    if (queryString != null) {
        if (queryString.indexOf("&" + field + "=") !== -1) {
          history.push("/fbpixel"); // fb fixel firing
        } else {
          history.push(
            "/thankyou?uuid=" + visitorParameters.visitor_parameters.uuid
          );
        }
      } else {
        history.push(
          "/thankyou?uuid=" + visitorParameters.visitor_parameters.uuid
        );
      }
}
}

const gtmDataLayer = (current_question, element) => {
  let question = "";
  let answer = "";

  switch (current_question) {
    case "bornBefore":
      question = "year";
      break;
    case "gender":
      question = "gender";
      break;
    case "lookingForInsurance_btn":
      question = "looking for insurance";
      break;
    case "DobMonth":
      question = "month";
      break;
    case "DobDay":
      question = "day";
      break;
    case "DobYear":
      question = "year of birth";
      break;
    case "post_code_btn":
      question = "postcode";
      break;
    case "basic_details":
      question = "personal details";
      break;
    // case "":
    //   question = "mobile verification";
    //   break;
    case "preferred_time":
      question = "preferred time";
      break;
  }
  GTMDataLayer({
    question: question,
    answer: answer,
  });
};
  return (
    <>
      {/* <!--  ========= FormSection  Area Start  ========= --> */}
      <div ref={scrollfrmv2div}>

        <form ref={splitForm} name="split_form" id="user_form" method="POST" autoComplete="off">

          <BasicQuestionnaireOne
            className={`form-group text-center ${state.showSlide1}`}
            textHeading="Were you born before 1974?"
            validation={register}
            slideChange={slideChange}
            getValues={getValues}
          />

          <BasicQuestionnaireTwo
            className={`form-group text-center ${state.showSlide2}`}
            textHeading="What is your gender?"
            validation={register}
            slideChange={slideChange}
            slidePreChange={slidePreChange}
            getValues={getValues}
          />

          <BasicQuestionnaireThree
            className={`form-group text-center ${state.showSlide3}`}
            textHeading="Why are you looking for over 50 life insurance?"
            validation={register}
            slideChange={slideChange}
            slidePreChange={slidePreChange}
            getValues={getValues}
            setLookingForInsuranceList={setLookingForInsuranceList}
          />

          <Month
            className={`form-group text-center ${state.showSlide4}`}
            textHeading="Tap the month you were born"
            validation={register}
            slideChange={slideChange}
            slidePreChange={slidePreChange}
            getValues={getValues}
            arrMonths={arrMonths}
            handleSelectedMonth={handleSelectedMonth}
          />

          <Day
            className={`form-group text-center ${state.showSlide5}`}
            textHeading={`What day in ${selectedMonth} were you born?`}
            validation={register}
            slideChange={slideChange}
            slidePreChange={slidePreChange}
            getValues={getValues}
            maxDays={maxDays}
            handleSelectedDay={handleSelectedDay}
          />

          <Year
            className={`form-group text-center ${state.showSlide6}`}
            textHeading="Tap your year of birth"
            validation={register}
            slideChange={slideChange}
            slidePreChange={slidePreChange}
            getValues={getValues}
            isLeapYear={isLeapYear}
          />

          <PostCode
            className={`form-group text-center ${state.showSlide7}`}
            textHeading="Great! Let's confirm that you live in a qualifying postcode"
            validation={register}
            validationMsg={errors}
            trigger={trigger}
            splitForm={splitForm}
            setError={setError}
            getValues={getValues}
            clearErrors={clearErrors}
            slideChange={slideChange}
            slidePreChange={slidePreChange}
            customCoverTerm ={customCoverTerm}
          />

          <BasicDetails
            className={`form-group text-center ${state.showSlide8}`}
            textHeading="We're searching for the best policies for you!"
            validation={register}
            slideChange={slideChange}
            slidePreChange={slidePreChange}
            getValues={getValues}
            trigger={trigger}
            setError={setError}
            validationMsg={errors}
            basic_onchangehandle={basic_onchangehandle}
            namevalidate={namevalidate}
            emailValidCheck={emailValidCheck}
            phoneValidCheck={phoneValidCheck}
            reff={scrollRef}
            disabled={btnDisabled}
            formSubmit={addBasicDetails}
          />

          <AdditionalDetails
            className={`form-group text-center ${state.showSlide9}`}
            textHeading="Last Step! You're Quote is Almost Ready"
            validation={register}
            slideChange={slideChange}
            slidePreChange={slidePreChange}
            getValues={getValues}
            trigger={trigger}
            setError={setError}
            validationMsg={errors}
            basic_onchangehandle={basic_onchangehandle}
            phoneValidCheck={alterPhoneValidCheck}
            reff={scrollRef}
            setPreferredTimeForCall={setPreferredTimeForCall}
            disabled={btnDisabled}
            formSubmit={questionSubmit}
            buttonValue={buttonValue}
            progressVal={progressVal}
          />

<BasicQuestionnaireFour
            className={`form-group text-center ${state.showSlide10}`}
            textHeading="Are you a Smoker?"
            validation={register}
            slideChange={slideChange}
            slidePreChange={slidePreChange}
            getValues={getValues}
          />
           <BasicQuestionnaireFive
              className={`form-group text-center ${state.showSlide11}`}
              textHeading="Amount of Cover?"
              validation={register}
              slideChange={slideChange}
              slidePreChange={slidePreChange}
              getValues={getValues}
              validationMsg={errors}
              trigger={trigger}
          />
                <BasicQuestionnaireSix
        className={`form-group text-center ${state.showSlide12}`}
        textHeading="Preferred Coverage Term?"
        validation={register}
        slideChange={slideChange}
        slidePreChange={slidePreChange}
        getValues={getValues}
        validationMsg={errors}
        trigger={trigger}
       // onClick={finalSubmit}
/>
        </form>
      </div>
    </>
  );
}

export default FormYLI00_F6_5;