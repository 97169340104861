import React from 'react';
import AnchorAdv from "../../../UI/AnchorAdv";
import FbViewPixel from '../../../../Utility/FbViewPixel';
const AdvHeader = () => {
  return (
    <>
      <FbViewPixel />
      <header>
          <div className="container">
            <div className="row">
                <div className="col-lg-12 text-center advpara">
                    <p>Advertorial</p>
                </div>
               <div className="col-lg-6 col-md-6 col-sm-6 col-6 logo">
                <AnchorAdv dataId="SITE_lOGO"><img src="/assets/FB_ADV1/img/logo.png" alt="" /></AnchorAdv>
               </div>
               <div className="col-lg-6 col-md-6 col-sm-6 col-6 text-end">
                <AnchorAdv dataId="TOP_CTA_BTN" className="get_btn btn">Get My Quotes</AnchorAdv>
               </div>
            </div>
          </div>
      </header>
    </>
  )
}
export default AdvHeader;
