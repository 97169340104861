import React, { useState } from "react";
import TextField from '../../../UI/TextField';
import Telephone from "../../../UI/Telephone";
import Email from "../../../UI/Email";
import InputButton from "../../../UI/InputButton";
import ProgressBar from "../YLI00_F5/ProgressBar";
import PrivacyPolicy from '../PrivacyPolicy';
import { Salutation } from "../../../../Constants/Constants";
import SelectBox from "../../../UI/SelectBox";
const BasicDetails = ({
  className,
  slideChange,
  slidePreChange,
  textHeading,
  validation,
  validationMsg,
  basic_onchangehandle,
  setError,
  namevalidate,
  emailValidCheck,
  phoneValidCheck,
  reff,
  disabled,
  formSubmit
}) => {

  const [showprivacy, setshowprivacy] = useState(false);

  const handleprivacyShow=()=>{
    setshowprivacy(true);
  };
  const handleprivacyClose=()=>{
    setshowprivacy(false);
  };

  return (
    <>
    <div id="slide8" className={`col-12 top_minus_1 ${className}`}>
      <ProgressBar value="90" />
      <div className="col-lg-12 col-md-12 col-sm-12 px-3 form_cont">
          <div className="col-lg-12 col-md-12 col-sm-12 form-group">
              <label>{textHeading}</label>
              <div className="row">
                  <div className="col-lg-8 offset-lg-2 col-md-12 col-sm-12 mt-1 dmy">
                      <p className="text-center para"><strong>Who is this quote for?</strong></p>
                      <div className="form-group" ref={(el) => (reff.current['lstSalutation'] = el)}>
                      <SelectBox
                        className="form-control"
                        OptionValue={Salutation}
                        name="lstSalutation"
                        id="lstSalutation"
                        //onChange={getValue}
                        myRef={validation({ required: "Please Select Salutation" })}
                        validationMsg={
                          validationMsg.lstSalutation && validationMsg.lstSalutation.message
                        }
                      />
                      </div>
                      <div className=" form-group" ref={(el) => (reff.current['txtFName'] = el)}>
                        <TextField
                          type="text"
                          className="form-control"
                          placeholder="First Name"
                          name="txtFName"
                          id  ="txtFName"
                          validation={validation({
                          required: "Please Enter First Name",
                          minLength: {
                              value: 3,
                              message: "Please Enter Valid First Name",
                          },
                          pattern: {
                              value: /^[a-zA-Z\s]+$/,
                              message: "Your Name is Invalid. Please Recheck",
                          },
                          })}
                          validationMsg={
                              validationMsg.txtFName && validationMsg.txtFName.message
                          }
                          onChange ={basic_onchangehandle}
                          onBlur={namevalidate}
                        />
                      </div>
                  </div>
                  <div className="col-lg-8 offset-lg-2 col-md-12 col-sm-12 mt-1 dmy">
                    <div className=" form-group" ref={(el) => (reff.current['txtLName'] = el)}>
                      <TextField
                        type="text"
                        className="form-control"
                        placeholder="Surname"
                        name="txtLName"
                        id  ="lname"
                        validation={validation({
                        required: "Please Enter Surname",
                        minLength: {
                            value: 3,
                            message: "Please Enter Valid Surname",
                        },
                        pattern: {
                            value: /^[a-zA-Z\s]+$/,
                            message: "Your Name is Invalid. Please Recheck",
                        },
                        })}
                        validationMsg={
                            validationMsg.txtLName && validationMsg.txtLName.message
                        }
                        onChange ={basic_onchangehandle}
                        onBlur={namevalidate}
                      />
                    </div>
                  </div>
                  <div className="col-lg-8 offset-lg-2 col-md-12 col-sm-12 mt-1 dmy">
                      <p className="text-center para"><strong>Where should we send your quote?</strong></p>
                      <div className=" form-group" ref={(el) => (reff.current['txtPhone'] = el)}>
                      <Telephone
                        name="txtPhone"
                        className="form-control"
                        placeholder="Mobile Phone"
                        maxlength="11"
                        onBlur={phoneValidCheck}
                        onKeyPress={e => (!/[0-9]+$/.test(e.key)) && e.preventDefault()}
                        validation={validation({ required: true })}
                        validationMsg={
                          validationMsg.txtPhone && "Please Enter Valid Phone Number"
                        }
                        onChange ={basic_onchangehandle}
                      ></Telephone>
                      </div>
                  </div>
                  <div className="col-lg-8 offset-lg-2 col-md-12 col-sm-12 mt-1 dmy">
                    <div className=" form-group mb-1" ref={(el) => (reff.current['txtEmail'] = el)}>
                      <Email 
                        name="txtEmail"
                        className="form-control"
                        placeholder="Email Address"
                        onBlur={emailValidCheck}
                        validation={validation({
                          required: "Please Enter Valid Email Address",
                          pattern: {
                          value:
                              /^([\w-\.]+)@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/,
                          message: "Invalid Email Address",
                          },
                        })}
                        validationMsg={
                          validationMsg.txtEmail && validationMsg.txtEmail.message
                        }
                        onChange={basic_onchangehandle}
                        >
                      </Email>
                    </div>
                  </div>
              </div>
          </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12 bord text-center">
          <InputButton
            style={{ float: "none" }}
            name="submit_form"
            className="next-but btn my-bt sctop1 next08"
            value="Get My Free Quote &gt;&gt;"
            btnType="button"
            onClick={formSubmit}
            disabled={disabled}
            id="btn_submit_claim"
          />
          <div className="col-12 text-center mt-1">
              <a id="back7" className="back_btn sctop" name="submit_form_back" onClick={slidePreChange}><img src="/assets/YLI00_F5/img/arrow.png" alt="" />  Previous </a>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 text-center">
              <div className="col-lg-8 offset-lg-2 col-12 authorise text-center">
                  <p className='d-none'>You will receive a call from an FCA authorised life insurance advisor if we feel we can help you based on the information you have provided.</p>
                  <p className=''>By continuing you will receive a call from an FCA authorised life insurance consultant if we feel we can help you based on the information you have provided. More details can be found in our <a className="rp_clk" data-bs-toggle="modal" data-bs-target="#prvcy" onClick={handleprivacyShow}>Privacy Policy</a></p>
              </div>
          </div>
          <div className="text-center safe-text"> 
              <img src="/assets/YLI00_F5/img/ico-safe.png" alt="Safe, Secure &amp; Confidential" width="12" height="15" />Safe | Secure | Privacy Protected
          </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12 text-center d-none">
          <div className="offset-lg-2 col-lg-8 col-lg-offset-2 terms mob-0">
              <div className="consent text-center">
                  <p>By submitting your enquiry, you agree to our <a className="rp_clk" data-bs-toggle="modal" data-bs-target="#prvcy" onClick={handleprivacyShow}>Privacy Policy</a> and agree we may use the contact details provided to contact you about your life insurance quote.
                  </p>
              </div>
          </div>
      </div>
    </div>
    <PrivacyPolicy
      showstatus={showprivacy} 
      hidepopup ={handleprivacyClose}
    />
    </>
  );
};
export default BasicDetails;
