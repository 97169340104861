//import {useState} from 'react';
import { Api } from "../api/Api";

export const usePostCodeAddress = () => {
  const getPostcodeAddress = async (postcode) => {
    const response = await Api.get(
      "v1/get-addr-list-postcode-api?postcode=" + postcode,
      {}
    );
    return response;
  };
  const getSplitPostcodeAddress = async (AddressID, postcode) => {
    const response = await Api.get(
      "v1/get-addr-split-postcode-api?AddressID=" +
        AddressID +
        "&postcode=" +
        postcode,
      {}
    );
    return response;
  };
  return { getPostcodeAddress, getSplitPostcodeAddress };
};
