import React, { useState } from "react";
import ProgressBar from "../YLI00_F5/ProgressBar";
import RadioButton from "../../../UI/RadioButton";

const Month = ({
  className,
  slideChange,
  slidePreChange,
  textHeading,
  validation,
  validationMsg,
  trigger,
  splitForm,
  setError,
  arrMonths,
  handleSelectedMonth
}) => {

  const nextSlide = async (e) => {
    handleSelectedMonth(e.target.defaultValue);
    changeActiveStatus(e);
    slideChange(e);
  };


  const [activestatus, setActivestatus] = useState(-1);

  const changeActiveStatus = (e) => {

      setActivestatus(e.target.id);
  }


  return (
    <>
      <div id="slide4" className={`col-12 top_minus_1 ${className}`}>
        <ProgressBar value="50" />
        <div className="col-lg-12 col-md-12 col-sm-12 px-3 form_cont">
          <div className="col-lg-12 col-md-12 col-sm-12 form-group">
              <label>{textHeading}</label>
              <div className="row">
                  <div className="col-lg-8 offset-lg-2 col-md-12 col-sm-12 mt-1 dmy mar">

                    {arrMonths.map((month,index) => (  
                      <RadioButton
                        key={month}
                        id={index}
                        value={month}
                        name="DobMonth"
                        className="radio-button custom-radio-button sctop1 next-but"
                        labelName={month}
                        labelClassName={
                          activestatus==index?"icon_option1 load mobload sctop1 next04 next-but active":
                          "icon_option1 load mobload sctop1 next04 next-but"
                        }
                        onClick={nextSlide}
                        validation={validation()}
                      /> 
                    ))}
                  
                  </div>
                  <span id="fullDOB_err" className="error_msg error"></span>
              </div>
          </div>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 bord text-center">
          <div className="col-12 text-center mt-1">
              <a id="back3" className="back_btn sctop1" name="month_back" onClick={slidePreChange}><img src="/assets/YLI00_F5/img/arrow.png" alt="" />  Previous </a>
          </div>
          <div className="text-center safe-text"> 
              <img src="/assets/YLI00_F5/img/ico-safe.png" alt="Safe, Secure &amp; Confidential" width="12" height="15" />Safe | Secure | Privacy Protected
          </div>
        </div>
      </div>
    </>
  );
};

export default Month;
