import React from "react";
import AnchorAdv from "../../../UI/AnchorAdv";
import FbViewPixel from "../../../../Utility/FbViewPixel";
const AdvHeader = () => {
  return (
    <>
      <FbViewPixel />
      <header>
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-6 col-6 logo">
              <AnchorAdv dataId="SITE_lOGO">
                <img src="/assets/YLI_ADV2/img/logo.png" alt="" />
              </AnchorAdv>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4  col-sm-6 col-6 hdrbtn_sec text-end">
              <AnchorAdv className="btn header_btn" dataId="TOP_CTA_BTN">
                {" "}
                Get Your Quote
                <img src="/assets/YLI_ADV2/img/arrow.svg" alt="" />
              </AnchorAdv>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};
export default AdvHeader;
