import React, { useState } from "react";
import "../../../assets/YLI_Contact/css/YLI_Contact.scss";
import Cookiespolicy from "./Cookiespolicy";
import PrivacyPolicy from "./PrivacyPolicy";
import { useContact } from "../../../Hooks/useContact";

const YLI_Contact = () => {
  const [showPrivacy, setShowPrivacy] = useState("hide");
  const [showCookie, setShowCookie] = useState("hide");
  const { contactSubmit, constactLoading } = useContact();
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [emailValid, setEmailValid] = useState(false);
  const [phoneValid, setPhoneValid] = useState(false);
  const [messageValid, setMessageValid] = useState(false);
  const [contactSuccess, setContactSuccess] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email == "") {
      setEmailValid(true);
    } else if (phone == "" || (phone.replace(/\D/g, '').length == 0)) {
      setPhoneValid(true);
    } else if (message == "") {
      setMessageValid(true);
    } else {
      const txtPhone = phone.replace(/\D/g, '');
      try {
        const response = await contactSubmit(
          phone,
          email,
          message
        )
        if (response.data.status === "Success") {
          setTimeout(
            () => setContactSuccess(true),
            1000
          );
          setEmail('');
          setPhone('');
          setMessage('');
        }
      } catch (e) {
        console.warn(e);
      }
    }
  }

  const [showprivacy, setshowprivacy] = useState(false);
  const [showpcookies, setshowpcookies] = useState(false);

  const handleprivacyShow = () => {
    setshowprivacy(true);
  };
  const handleprivacyClose = () => {
    setshowprivacy(false);
  };
  const handleCookieShow = () => {
    setshowpcookies(true);
  };
  const handleCookieHide = () => {
    setshowpcookies(false);
  };

  const checkPhoneValid = (e) => {
    var regex = new RegExp("^[0-9]+$");
    var key = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (!regex.test(key)) {
      e.preventDefault();
      return false;
    }
  }

  return (
    <>
      <div className="YLI_Contact">
        <header className="header">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                <p className="m-0">Advertorial</p>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 col-12 contact_logo">
                <img src="/assets/YLI_Contact/img/logo.png" alt="" />
              </div>
            </div>
          </div>
        </header>
        <section className="contact_sec">
          <div className="container">
            <div className="row">
              <div className="offset-lg-3 col-lg-6 col-12">
                <div className="contact_area">
                  <div className="col-lg-12 text-center col-12">
                    <h1>Contact Us</h1>
                  </div>
                  <form>
                    <div className="text-center">
                      <span id="contact_success" className="contact_success" style={contactSuccess ? { display: "block" } : { display: "none" }}>Your email has been sent successfully</span>
                    </div>
                    <div className="form-group">
                      <label htmlFor="">Email Address</label>
                      <input type="email" name="emailid" id="emailid" className="form-control" placeholder="Email Address" onChange={(e) => setEmail(e.target.value)}
                        value={email} onBlur={() => setEmailValid(false)} />
                      <span id="email_err" className='error_msg' style={emailValid ? { display: "block" } : { display: "none" }}>Please Enter Email Address</span>

                    </div>
                    <div className="form-group">
                      <label htmlFor="">Phone Number</label>
                      <input type="text" name="phonenumber" id="phonenumber" className="form-control" placeholder="Phone Number" onChange={(e) => setPhone(e.target.value)}
                        value={phone} onKeyPress={(e) => checkPhoneValid(e)} onBlur={() => setPhoneValid(false)} maxLength="11" />
                      <span id="phone_err" className='error_msg' style={phoneValid ? { display: "block" } : { display: "none" }}>Please Enter Phone Number</span>

                    </div>
                    <div className="form-group ">
                      <label htmlFor="comment">Message</label>
                      <textarea className="form-control" rows="5" name="message" id="message" placeholder="Message" onChange={(e) => setMessage(e.target.value)} value={message} onBlur={() => setMessageValid(false)}></textarea>
                      <span id="message_err" className='error_msg' style={messageValid ? { display: "block" } : { display: "none" }}>Please Enter Message</span>

                    </div>
                    <div className="text-center mt-3">
                      <button type="submit" id="contactbutton" className="btn send_btn center-block" onClick={handleSubmit}>Submit</button>
                    </div>
                    <br />

                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>

        <footer>
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                <p>DISCLAIMER: The material on this website is intended only to provide a summary and general overview on matters of interest.</p>
              </div>

              <div className="offset-lg-2 col-md-2 col-lg-10 offset-md-2 col-md-10 col-sm-12 col-12">
                <div className="row">
                  <div className="col-lg-3 col-sm-3 col-12 text-center">
                    <img src="/assets/YLI_Contact/img/logo.png" alt="fut_logo" />
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-9 col-12">
                    <ul>
                      <li>
                        <a
                          className="rp_clk"
                          onClick={handleprivacyShow}
                          data-bs-target="#prvcy"
                        >
                          Privacy Policy
                        </a>{" "}
                      </li>
                      <li style={{ border : "none" }}>
                        <a
                          data-dismiss="modal"
                          onClick={handleCookieShow}
                          data-bs-target=".cookies"
                        >
                          Cookies
                        </a>{" "}
                      </li>
                      <PrivacyPolicy showstatus={showprivacy} hidepopup={handleprivacyClose} />
                      <Cookiespolicy showstatus={showpcookies} hidepopup={handleCookieHide} />
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default YLI_Contact;
