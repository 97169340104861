import React, { useEffect, useState } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import * as EnvConstants from "../src/Constants/EnvConstants";
import { QueryStringProvider } from "./Contexts/QueryString";
import { VisitorParamsProvider } from "./Contexts/VisitorParams";
import { queryString } from "./Utility/QueryString";
import HomePage from "./Components/Pages/HomePage";
import FbPixel from "./Components/Pages/FbPixel";
import "./App.scss";
import MCS_V1 from "./Components/Pages/Splits/MCS_V1";
import YLI00_F5 from "./Components/Pages/Splits/YLI00_F5";
import YLI00_F6 from "./Components/Pages/Splits/YLI00_F6";
import YLI00_F7 from "./Components/Pages/Splits/YLI00_F7";
import Thankyou from "./Components/Pages/Thankyou";
import Unqualified from "./Components/Pages/Unqualified";
import AMCS_ADV_1 from "./Components/Pages/Advertorials/AMCS_ADV_1";
import FB_ADV1 from "./Components/Pages/Advertorials/FB_ADV1";
import YLI_ADV2 from "./Components/Pages/Advertorials/YLI_ADV2";
import YLI_ADV3 from "./Components/Pages/Advertorials/YLI_ADV3";
import YLI_ADV4 from "./Components/Pages/Advertorials/YLI_ADV4";
import YLI_GLI_ADV1 from "./Components/Pages/Advertorials/YLI_GLI_ADV1";
import YLI_GLI_Male_ADV2 from "./Components/Pages/Advertorials/YLI_GLI_Male_ADV2";
import YLI_Contact from "./Components/Includes/Layouts/YLI_Contact";
import Signature from "./Components/Pages/Signature";
import { RedirectProvider } from "./Contexts/RedirectContext";
import UserFormMCS_V1 from "./Components/Forms/UserFormMCS_V1";
import NotFound from "./Components/Pages/NotFound";
import Followup from "./Components/Pages/Followup/Followup";
import Suppliers from "./Components/Pages/Suppliers";
import YLI00_G1 from "./Components/Pages/Splits/YLI00_G1";
import YLI00_F6_5 from "./Components/Pages/Splits/YLI00_F6_5";
import FB_CB_ADV2 from "./Components/Pages/Advertorials/FB_CB_ADV2";
import YLI00_F6_6 from "./Components/Pages/Splits/YLI00_F6_6";
import AdtopiaLoadDynamic from "./Utility/AdtopiaLoadDynamic";

const App = () => {
  const [visitorParameters] = useState({ visitor_parameters: [], data: [] });
  const [redirectUrl] = useState({ redirectUrl: '' });
  useEffect(() => {
    document.title = EnvConstants.AppTitle;
  }, []);
  return (
    <>
      <VisitorParamsProvider value={{ visitorParameters }}>
        <QueryStringProvider value={{ queryString }}>
          <RedirectProvider value={{ redirectUrl }}>
            <BrowserRouter>
              <Route exact path="/" component={HomePage} />
              <Route exact path={["/MCS_V1"]} component={MCS_V1} />
              <Route exact path={["/AMCS_ADV_1"]} component={AMCS_ADV_1} />
              <Route exact path={["/YLI_GLI_ADV1"]} component={YLI_GLI_ADV1}/>
              <Route exact path={["/YLI_GLI_Male_ADV2"]} component={YLI_GLI_Male_ADV2}/>
              <Route exact path={["/over50lifeinsurance/FB_ADV1"]} component={FB_ADV1} />
              <Route exact path={["/life_insurance/FB_CB_ADV2"]} component={FB_CB_ADV2} />
              <Route exact path={["/over50lifeinsurance/YLI_ADV2"]} component={YLI_ADV2} />
              <Route exact path={["/over50lifeinsurance/YLI_ADV3"]} component={YLI_ADV3} />
              <Route exact path={["/over50lifeinsurance/YLI_ADV4"]} component={YLI_ADV4} />
              <Route exact path={["/YLI_Contact"]} component={YLI_Contact} />
              <Route exact path={["/over50lifeinsurance/0602YLI00-F5"]} component={YLI00_F5} />
              <Route exact path={["/over50lifeinsurance/0602YLI00-F6"]} component={YLI00_F6} />
              <Route exact path={["/over50lifeinsurance/0602YLI00-F6.5"]} component={YLI00_F6_5} />
              <Route exact path={["/over50lifeinsurance/0602YLI00-F7"]} component={YLI00_F7} />
              {/* <Route exact path={["/over50lifeinsurance/0602YLI00-G1"]} component={YLI00_G1} /> */}
              <Route exact path={["/over50lifeinsurance/0602YLI00-F6.6"]} component={YLI00_F6_6} />
              <Route exact path={["/0602YLI00-G1"]} component={YLI00_G1} />
              <Route exact path={"/signature"} component={Signature} />
              <Route exact path={["/thankyou"]} component={Thankyou} />
              <Route exact path={["/unqualified"]} component={Unqualified} />
              <Route exact path={["/fbpixel"]} component={FbPixel} />
              <Route exact path={["/user-info"]} component={UserFormMCS_V1} />
              <Route exact path={["/404"]} component={NotFound} />
              <Route exact path={["/followup"]} component={Followup} />
              <Route exact path={["/suppliers"]} component={Suppliers} />
              <Route exact path={["/google","/testing","/index"]} component={AdtopiaLoadDynamic}/>
            </BrowserRouter>
          </RedirectProvider>
        </QueryStringProvider>
      </VisitorParamsProvider>
    </>
  );
};

export default App;
