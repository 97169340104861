import React, { useState } from 'react';
import PrivacyPolicy from '../PrivacyPolicy';
import Terms from '../Terms';
const Footer = () => {
  const [showprivacy, setshowprivacy] = useState(false);
  const [showTerms, setshowTerms] = useState(false);

  const handleprivacyShow=()=>{
      setshowprivacy(true);
  };
  const handleprivacyClose=()=>{
      setshowprivacy(false);
  };
  const handleTermsShow=()=>{
      setshowTerms(true);
  };
  const handleTermsHide=()=>{
      setshowTerms(false);
  };

  return (
    <>
      <section className="fut">
        <div className="container">
            <div className="col-lg-12">
                <div className="center-block text-center log">
                    <a href="#" className="logo1"><img src="/assets/YLI00_F6_5/img/log_wht.png" alt="logowht" /></a>
                </div>
                <p className="copyright">
                    Choosing to take out a life insurance policy is a huge step and it can be daunting and scary if you don't know exactly what you are looking for. There are many types of cover available, and many different companies offering it. It is important that you understand the various options that are available to you. Life insurance is not a "one size fits all" situation, so why should you take the first plan that comes along when cheaper and/or more suitable options may be available?
                </p>
                <p className="copyright">
                    Taking out a life insurance policy is a big thing to consider for anybody, and you should weigh up your options before deciding on the plan that is right for you. Here at YourLife Insured, we make this process easier by allowing you to compare numerous life insurance plans from the UK’s top insurers to find the most suitable and beneficial plan for you based on your specific situation. Not only will working with us inevitably save you a lot of time, but you are sure to save money because of the insurer relationships our partners have.
                </p>
            </div>
        </div>
      </section>
      <footer className="text-center">
        <div className="container">
            <div className="col-lg-12">
                <div className="col-lg-12 text-center mob-0">
                    <div className="spine">
                        <p>We do not charge a fee for this service, but we do receive a commission directly from our partners following successful introductions from this website. Any information we provide is for the purpose of illustrating the products promoted on this site only and should not be read as financial advice.</p>
                    </div>
                </div>
                <div>
                <ul>
                     <li>*£20,000 Life Cover For Only £1.25 a week – is based on a 52yr old non-smoker. Actual sum insured is £20,571</li><br/>
                </ul>
                </div>
                <div className="col-lg-12 text-center mob-0">
                    <div className="spine">
                        <p>Copyright © YourLife Insured. 2022 | All Rights Reserved</p>
                        <p>yourlife-insured.co.uk</p>
                    </div>
                </div>
                <ul>
                    <li><a className="rp_clk" data-bs-toggle="modal" data-bs-target="#terms" onClick={handleTermsShow}>Terms and Conditions</a></li>
                    <li>|</li>
                    <li><a className="rp_clk" data-bs-toggle="modal" data-bs-target="#prvcy" onClick={handleprivacyShow}>Privacy Policy</a></li>
                </ul>
            </div>
        </div>
      </footer>
      <PrivacyPolicy
        showstatus={showprivacy} 
        hidepopup ={handleprivacyClose}
      />
      <Terms
        showstatus={showTerms} 
        hidepopup ={handleTermsHide}
      />
    </>
  );
}

export default Footer;