import React from 'react';
import FbViewPixel from '../../../../Utility/FbViewPixel';

const Header = () => {
  return (
    <>
      <FbViewPixel />
      <header>
        <div className="container">
          <div className="row">
              <div className="col-lg-12 text-center">
                <img src="/assets/YLI00_F5/img/logo.png" alt="" className="logo" />     
              </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;