import React, { useState } from "react";
import RadioButton from "../../../UI/RadioButton";
const BasicQuestionnaireOne = ({
  className,
  slideChange,
  textHeading,
  validation
}) => {
  const nextSlide = async (e) => {
    changeActiveStatus(e);
    slideChange(e);
  };

  const [activestatus, setActivestatus] = useState(0);

  const changeActiveStatus = (e) => {

    if (e.target.value == 1) {
      setActivestatus(1);

    } else if (e.target.value == 2) {
      setActivestatus(2);
    }
  }

  return (
    <>
      <div id="slide1" className={`col-12 top_minus_1 ${className}`}>
        <div className="col-lg-12 col-md-12 col-sm-12 px-3">
          <div className="offset-lg-3 col-lg-6 col-md-12 form_cont text-center">
            <div className="row">
              <div className="form-group">
                <label className="text-center">{textHeading}</label>
                <RadioButton
                  value="1"
                  name="bornBefore"
                  className="radio-button custom-radio-button sctop next-but"
                  labelName="Yes"
                  labelClassName={
                    activestatus==1 ? 'icon_option load sctop next01 active' :
                      'icon_option load sctop next01'}
                  onClick={nextSlide}
                  validation={validation()}
                />

                <RadioButton
                  value="2"
                  name="bornBefore"
                  className="radio-button custom-radio-button sctop next-but"
                  labelName="No"
                  labelClassName={
                    activestatus==2 ? 'icon_option load mobload sctop next01 active' :
                      'icon_option load mobload sctop next01'
                  }
                  onClick={nextSlide}
                  validation={validation()}
                />

              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 bord text-center">
          <div className="text-center safe-text">
            <img src="/assets/YLI00_F5/img/ico-safe.png" alt="Safe, Secure &amp; Confidential" width="12" height="15" />Safe | Secure | Privacy Protected
          </div>
        </div>
      </div>
    </>
  );
};
export default BasicQuestionnaireOne;
