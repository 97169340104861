import React, {useEffect, useState, useContext,} from 'react';
import "../../assets/Thankyou/css/thankyou.scss"
import ThankyouFooter from '../Includes/Layouts/ThankyouFooter';
import ThankyouHeader from '../Includes/Layouts/ThankyouHeader';
import VisitorParamsContext from "../../Contexts/VisitorParams";
import { userInfo } from '../../Hooks/userInfo';

const Thankyou = () => {
  
  const [userFirstName, setUserFirstName] = useState("");
  const [userLastName, setUserLastName] = useState("");
  const { visitorParameters } = useContext(VisitorParamsContext);
  const { getUserInfo } = userInfo();
  const queryParams = new URLSearchParams(window.location.search);
  const queryuuid = queryParams.get('uuid');
  const first_name = localStorage.getItem('first_name');
  const last_name = localStorage.getItem('last_name');
  let uuid = "";

  useEffect(()=>{
    window.scrollTo({
       top: 0,
       left: 0,
       behavior: 'smooth'
    });
    CheckUser();
  }, []);

  const CheckUser = async () => {
    if(first_name != null && last_name != null ) {
       setUserFirstName(first_name);
       setUserLastName(last_name);
       localStorage.clear();
    } else {
       if(visitorParameters.visitor_parameters != undefined && visitorParameters.visitor_parameters != '')
       {
          uuid = visitorParameters.visitor_parameters.uuid;
       } else {
          uuid = queryuuid;
       }
       const userDetails = await getUserInfo(uuid);
       setUserFirstName(userDetails.data.first_name);
       setUserLastName(userDetails.data.last_name);
       localStorage.clear();
    }
  }
  
  return (
    <>
      <div className="yourlifethanku">
        <ThankyouHeader />
        <section>
          <div className="container">
              <div className="row">
                <div className="col-lg-6 text-left mb_bg">
                    <h1>Thank You {userFirstName} {userLastName},</h1>
                    <p>One of our trusted Life Insurance advisors needs to speak with you on the phone briefly to get a bit more information and help determine the best option for you.</p>
                    <p>And don’t worry! This phone call does NOT commit you to buying anything and is simply to help you choose the best option.</p>
                </div>
                <div className="col-lg-6">
                </div>
              </div>
          </div>
        </section>
        <ThankyouFooter />
      </div>
    </>
  )
}

export default Thankyou;
