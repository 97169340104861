import React, { useState } from "react";
import ProgressBar from "../YLI00_F6_5/ProgressBar";
import RadioButton from "../../../UI/RadioButton";

const BasicQuestionnaireFour = ({
  className,
  slideChange,
  slidePreChange,
  textHeading,
  validation
}) => {
  const nextSlide = async (e) => {
    changeActiveStatus(e);
    slideChange(e);
  };

  const [activestatus, setActivestatus] = useState(0);

  const changeActiveStatus = (e) => {

    if (e.target.value == 'Female') {
      setActivestatus(1);

    } else if (e.target.value == 'Male') {
      setActivestatus(2);
    }
  }

  return (
    <>
      <div id="slide10" className={`col-12 top_minus_1 ${className}`}>
        <ProgressBar value="96" />
        <div className="col-lg-12 col-md-12 col-sm-12 px-3">
          <div className="offset-lg-3 col-lg-6 col-md-12 form_cont text-center">
            <div className="row ">
              <div className="form-group">
                <label className="text-center">{textHeading}</label>

                <RadioButton
                  value="9"
                  name="smoker"
                  className="radio-button custom-radio-button sctop1 next-but"
                  labelName="Yes"
                  labelClassName={
                    activestatus==1 ? 'icon_option load sctop1 next02 active':
                    'icon_option load sctop1 next02'
                  }
                  onClick={nextSlide}
                  validation={validation()}
                />

                <RadioButton
                  value="10"
                  name="smoker"
                  className="radio-button custom-radio-button sctop1 next-but"
                  labelName="No"
                  labelClassName={
                    activestatus==2 ? 'icon_option load sctop1 next02 active':
                    'icon_option load sctop1 next02'
                  }
                  onClick={nextSlide}
                  validation={validation()}
                />
              </div>
              <span id="cover_for_err" className="error_msg error"></span>
            </div>
          </div>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 bord text-center">
          <div className="col-12 text-center mt-1">
            <a id="back1" className="back_btn sctop1" name="smoker_back" onClick={slidePreChange}><img src="/assets/YLI00_F6_5/img/arrow.png" alt="" /> Previous </a>
          </div>
          <div className="text-center safe-text">
            <img src="/assets/YLI00_F6_5/img/ico-safe.png" alt="Safe, Secure &amp; Confidential" width="12" height="15" />Safe | Secure | Privacy Protected
          </div>
        </div>
      </div>
    </>
  );
};
export default BasicQuestionnaireFour;
