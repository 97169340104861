import React, { useState } from "react";
import Cookiespolicy from '../Cookiespolicy';
import PrivacyPolicy from '../PrivacyPolicy';
import Terms from '../Terms';

const AdvFooter = () => {
  const [showCookie, setshowCookie] = useState('show');
  var cookieName = "CookiesPolicyHide";

  const [showprivacy, setshowprivacy] = useState(false);
  const [showpcookies, setshowpcookies] = useState(false);
  const [showTerms, setshowTerms] = useState(false);

  const handleprivacyShow=()=>{
    setshowprivacy(true);
  };
  const handleprivacyClose=()=>{
    setshowprivacy(false);
  };
  const handleCookieShow=()=>{
    setshowpcookies(true);
  };
  const handleCookieHide=()=>{
    setshowpcookies(false);
  };
  const handleTermsShow=()=>{
    setshowTerms(true);
  };
  const handleTermsHide=()=>{
    setshowTerms(false);
  };

  const cookieClose = (e) => {
    e.preventDefault();
    setshowCookie('hide');
    if (cookieName != null) {
      var d = new Date();
      var cvalue = "Yes";
      d.setTime(d.getTime() + (1 * 24 * 60 * 60 * 1000));
      var expires = "expires=" + d.toUTCString();
      document.cookie = cookieName + "=" + cvalue + "; ";
      setshowCookie('hide');
    }
  }
  return (
    <>
      <footer className="text-center">
          <div className="container">
              <div className="col-lg-12">
                  <div className="col-lg-12 text-center mob-0">
                      <div className="spine">
                          <p>Copyright © YourLife Insured. 2022 | All Rights Reserved</p>
                          <p>yourlife-insured.co.uk</p>
                      </div>
                  </div>
                  <ul className="mb-5">
                      <li><a className="rp_clk" data-bs-toggle="modal" data-bs-target="#terms" onClick={handleTermsShow}>Terms and Conditions</a></li>
                      <li>|</li>
                      <li><a className="rp_clk" data-bs-toggle="modal" data-bs-target="#prvcy" onClick={handleprivacyShow}>Privacy Policy</a></li>
                  </ul>
              </div>
          </div>
      </footer>
      <div id="cookieBar" className={` cooki text-center ${showCookie}`}>
        <div className="col-lg-12 col-md-12 col-12 col-sm-12">
            <span>Cookies help us deliver our services. By using this website you agree to use of our cookies. <a href="#" className="rp_clk" data-bs-toggle="modal" data-bs-target="#cookies" onClick={handleCookieShow}><u>Learn More.</u></a> </span>
            <a className="rp_clk closecook" href="#" onClick={cookieClose} data-cookiename="CookiesPolicyHide" id="cookclose">Close <strong>X</strong></a>
        </div>
        <div className="clearfix"></div>
      </div>
      <PrivacyPolicy
        showstatus={showprivacy} 
        hidepopup ={handleprivacyClose}
      />
       <Cookiespolicy
         showstatus={showpcookies} 
         hidepopup ={handleCookieHide}
       />
       <Terms
         showstatus={showTerms} 
         hidepopup ={handleTermsHide}
       />
    </>
  )
}
export default AdvFooter;
