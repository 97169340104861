import React, { useState } from "react";
import ProgressBar from "../YLI00_F5/ProgressBar";
import RadioButton from "../../../UI/RadioButton";

const Day = ({
  className,
  slideChange,
  slidePreChange,
  textHeading,
  validation,
  validationMsg,
  trigger,
  splitForm,
  setError,
  maxDays,
  handleSelectedDay
}) => {

  var arrDays = [];
  for (let i = 1; i <= maxDays; i++) {
    arrDays.push(i);
  }


  const [activestatus, setActivestatus] = useState(-1);

  const changeActiveStatus = (e) => {

      setActivestatus(e.target.id);
  }


  const nextSlide = async (e) => {
    handleSelectedDay(e.target.defaultValue);
    changeActiveStatus(e);
    slideChange(e);
  };
  
  return (
    <>
      <div id="slide5" className={`col-12 top_minus_1 ${className}`}>
      <ProgressBar value="60" />
      <div className="col-lg-12 col-md-12 col-sm-12 px-3 form_cont">
        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
            <label>{textHeading}</label>
            <div className="row">
                <div className="col-lg-8 offset-lg-2 col-md-12 col-sm-12 mt-1 dmy mar">

                  {arrDays.map((day,index) => (  
                    <RadioButton
                      key={day}
                      value={day}
                      id={index+100}
                      name="DobDay"
                      className={`radio-button custom-radio-button sctop1 next-but`}
                      labelName={day}
                      labelClassName={
                        activestatus==index+100?"icon_option1 load mobload sctop1 next05 next-but active":
                        "icon_option1 load mobload sctop1 next05 next-but"
                      }
                      onClick={nextSlide}
                      validation={validation()}
                    /> 
                  ))}
                
                </div>
                <span id="fullDOB_err" className="error_msg error"></span>
            </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12 bord text-center">
        <div className="col-12 text-center mt-1">
            <a id="back3" className="back_btn sctop1" name="day_back" onClick={slidePreChange}><img src="/assets/YLI00_F5/img/arrow.png" alt="" />  Previous </a>
        </div>
        <div className="text-center safe-text"> 
            <img src="/assets/YLI00_F5/img/ico-safe.png" alt="Safe, Secure &amp; Confidential" width="12" height="15" />Safe | Secure | Privacy Protected
        </div>
      </div>
      </div>
    </>
  );
};

export default Day;
