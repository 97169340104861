import React from "react";
import "../../../assets/YLI_GLI_ADV1/scss/modules/custom.scss";

import Male from "../../../assets/YLI_GLI_ADV1/img/MCBMaleChequeImg1.jpg";
import agebanner from "../../../assets/YLI_GLI_ADV1/img/age-banner.png";  
import img20 from "../../../assets/YLI_GLI_ADV1/img/20-29.png";
import img30 from "../../../assets/YLI_GLI_ADV1/img/30-39.png";
import img40 from "../../../assets/YLI_GLI_ADV1/img/40-49.png";
import img50 from "../../../assets/YLI_GLI_ADV1/img/50-59.png";
import img60 from "../../../assets/YLI_GLI_ADV1/img/60.png";
import checked from "../../../assets/YLI_GLI_ADV1/img/checked.png";
import unchecked from "../../../assets/YLI_GLI_ADV1/img/do-not-enter.png";
import AdtopiaLoadAP from "../../../Utility/AdtopiaLoadAP";
import AdvHeader from '../../Includes/Layouts/YLI_GLI_ADV1/AdvHeader';
import AdvFooter from "../../Includes/Layouts/YLI_GLI_ADV1/AdvFooter";
import AnchorAdv from "../../UI/AnchorAdv";

const YLI_GLI_Male_ADV2 = () =>{
    return(
        <> 
        <AdtopiaLoadAP pageType="AP" splitName="YLI_GLI_Male_ADV2" />
        <div className="YLI_GLI_ADV1">
        <AdvHeader />
    
    <div className="container">
      <div className="col-12">
        <div className="row">
          <div className="col-lg-9 col-md-12">
            <div className="content">
              <div className="main text-center">
                
                  <h1 className="text-start">
                    <b>THIS JUST IN: UK Residents Can Now Lock In A £200,000* Payment For Their Loved Ones When They Die. </b>
                  </h1>
                  <h5 className="subhead text-start">
                  Thousands are jumping at this opportunity to help protect their family's financial security for the future.
                  </h5>
                  <AnchorAdv dataId="PARA_IMAGE_1">
                    <img className="img-responsive" src={Male} width="100%"/>
                  </AnchorAdv>
        
                  <p className="pt-2 text-start">
                    <AnchorAdv className="find_link" dataId="PARA_DESC_1"> Find out if you are eligible.</AnchorAdv>
                  </p>
        
                  <p className="text-start">
                    Did you know UK residents can now get approved for a £200,000 life insurance policy to help provide a lump-sum for family and loved ones? This opportunity to help ensure your family's financial security won't last forever, so <b> 
                       <AnchorAdv dataId="PARA_DESC_1">act fast to check your eligibility and secure your rates now.</AnchorAdv></b>
                  </p>
                  <p>It only take a few seconds to find out if you qualify. Tap below to get started. ⬇️</p>
                  
                    <h3 id="">
                      <b>Are You A UK Resident?</b>
                    </h3>
        
                    <AnchorAdv dataId="YES-button" className="btn btn-success btn-custom btn-green">
                      <i className="fa-solid fa-circle-check fa-custom"></i> 
                      <img className="img-checked" src={checked}/>Yes
                    </AnchorAdv>
                    <AnchorAdv dataId="NO-button" className="btn btn-danger btn-custom btn-red">
                    <i className="fa-solid fa-ban fa-custom"></i> 
                    <img className="img-checked" src={unchecked}/>No
                    </AnchorAdv>
        
                    <p className="text-start pt-4">
                      Getting a life insurance quote should be quick and easy, but many find it difficult to secure a policy that will protect their family. 
                    </p>

                    <div className="col-md-12 col-sm-12 col-12 d-lg-none d-md-block d-sm-block d-block mb-4">
                      <a href="#">
                        <img src={agebanner} alt="" className="img_fluid"/>
                      </a>
                    </div>

                    <p className="text-start">
                      Don't spend hours sear
                      ng for the best policy from multiple providers.   Follow the easy steps below to get a FREE personalised quote built for your family and your needs. 
                    </p>
        
        
                    <h3 className="text-start mt-4 age">Here's How to Get Started</h3>
                    <p className="text-start">
                      <b>Step 1 : </b>
                      Use our simple form to answer a few questions (it takes just two minutes)
                    </p>
                    <p className="text-start">
                      <b>Step 2: </b>
                      Once we have your details, we'll get you talking to a consultant.
                    </p>
                    <h3 className="age">Select Your Age NOW!</h3>
        
                    <div className="col-lg-12 text-center mt-4">
                      <div className="row m-0 justify-content-center">
                        <div className="col-lg-2 col-md-4 col-sm-6 col-6 age_find">
                        <AnchorAdv  dataId="MID_PARA_NUM">
                            <img src={img20} alt="" className="img-fluid"/>
                          </AnchorAdv>
                          <br/>
                        <span className="age_no pt-3">20 - 29</span>
                        </div>
                        <div className="col-lg-2 col-md-4 col-sm-6 col-6 age_find">
                        <AnchorAdv  dataId="MID_PARA_NUM">
                            <img src={img30} alt="" className="img-fluid"/>
                         </AnchorAdv>
                          <br/>
                          <span className="age_no pt-3">30 - 39</span>
                        </div>
                        <div className="col-lg-2 col-md-4 col-sm-6 col-6 age_find">
                          <AnchorAdv  dataId="MID_PARA_NUM">
                            <img src={img40} alt="" className="img-fluid"/>
                          </AnchorAdv>
                          <br/>
                          <span className="age_no pt-3">40 - 49</span>
                        </div>
                        <div className="col-lg-2 col-md-4 col-sm-6 col-5 age_find">
                          <AnchorAdv  dataId="MID_PARA_NUM">
                            <img src={img50} alt="" className="img-fluid"/>
                          </AnchorAdv>
                          <br/>
                          <span className="age_no pt-3">50 - 59</span>
                        </div>
                        <div className="col-lg-2 col-md-4 col-sm-6 col-6 age_find">
                          <AnchorAdv  dataId="MID_PARA_NUM">
                            <img src={img60} alt="" className="img-fluid"/>
                          </AnchorAdv>
                          <br/>
                          <span className="age_no pt-3">60 +</span>
                        </div>
                      </div>
        
                      <button class="btn btn-success btn-green pulse mt-4">
                        Get Your FREE Life Insurance Quotes
                      </button>
                    </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 d-lg-block d-md-none d-sm-none d-none">
            <div className="sidebar fixedsticky">
              <a href="#">
                <AnchorAdv dataId="img_fluid">
                <img src={agebanner} alt="" className="img_fluid"/>
                </AnchorAdv>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
   <AdvFooter />
  </div>
        </>
    )
}
export default YLI_GLI_Male_ADV2;
