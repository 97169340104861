import React, { useState } from "react";
import Cookiespolicy from "../Cookiespolicy";
import PrivacyPolicy from "../PrivacyPolicy";

const AdvFooter = () => {
  const [showprivacy, setshowprivacy] = useState(false);
  const [showpcookies, setshowpcookies] = useState(false);

  const handleprivacyShow = () => {
    setshowprivacy(true);
  };
  const handleprivacyClose = () => {
    setshowprivacy(false);
  };
  const handleCookieShow = () => {
    setshowpcookies(true);
  };
  const handleCookieHide = () => {
    setshowpcookies(false);
  };

  return (
    <>
      <footer>
        <div className="container">
          <div className="col-xl-12 col-lg-12 col-12 text-center">
            <p className="mb-1">
              DISCLAIMER: The material on this website is intended only to
              provide a summary and general overview on matters of interest.
            </p>
            <p className="mb-1">
              *£10 per month Life Insurance quoted rates are based on level term
              life cover for non-smokers and are subject to individual status
              and medical underwriting. Correct as of 17th February 2022.
            </p>
            <p className="text-center small_txt">
              £242,160 over 40 years - 25 years old born in 1997
              <br />
              £195,101 over 35 years - 30 years old born in 1992
              <br />
              £147,581 over 30 years - 35 years old born in 1987
              <br />
              £112,013 over 25 years - 40 years old born in 1982
              <br />
              £80,770 over 20 years - 45 years old born in 1977
              <br />
              £60,779 over 15 years - 50 years old born in 1972
              <br />
              £42,342 over 10 years - 55 years old born in 1967
              <br />
              £29,706 over 5 years - 60 years old born in 1962
              <br />
              £18,557 over 5 years - 65 years old born in 1957
              <br />
              £10,328 over 5 years - 70 years old born in 1952
              <br />
            </p>
          </div>
          <div className="col-xl-12 col-lg-12 col-12 down_footer">
            <div className="row">
              <div className="col-xl-2 col-lg-2 col-md-3 col-sm-3 col-12 mob text-start">
                <img
                  src="/assets/YLI_ADV2/img/logo.png"
                  className="footer_logo"
                  alt=""
                />
              </div>
              <div className="col-xl-10 col-lg-10 col-md-9 col-sm-9 col-12">
                <ul className="text-start">
                  <li>
                    <a
                      className="rp_clk"
                      onClick={handleprivacyShow}
                      data-bs-target="#prvcy"
                    >
                      Privacy Policy
                    </a>{" "}
                  </li>
                  <li>
                    <a
                      data-dismiss="modal"
                      onClick={handleCookieShow}
                      data-bs-target=".cookies"
                    >
                      Cookies
                    </a>{" "}
                  </li>
                  <li>
                    <a href={"/YLI_Contact"} target="_blank">
                      Contact Us
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <PrivacyPolicy showstatus={showprivacy} hidepopup={handleprivacyClose} />
      <Cookiespolicy showstatus={showpcookies} hidepopup={handleCookieHide} />
    </>
  );
};
export default AdvFooter;
