import React, { useState } from 'react';
import PrivacyPolicy from './PrivacyPolicy';
import Terms from './Terms';
const ThankyouFooter = () =>{

    const [showprivacy, setshowprivacy] = useState(false);
    const [showTerms, setshowTerms] = useState(false);

    const handleprivacyShow=()=>{
        setshowprivacy(true);
    };
    const handleprivacyClose=()=>{
        setshowprivacy(false);
    };
    const handleTermsShow=()=>{
        setshowTerms(true);
    };
    const handleTermsHide=()=>{
        setshowTerms(false);
    };

    return(
        <>
            <footer className="text-center">
                <div className="container">
                    <div className="col-lg-12">
                        <div className="col-lg-12 text-center mob-0">
                            <div className="spine">
                                <p>© 2022 YourLife Insured. All Rights Reserved.</p>
                                <p>yourlife-insured.co.uk</p>
                                <p className="mb-3">YourLife Insured in partnership with Prosperous Life</p>
                            </div>
                        </div>
                        <ul>
                            <li><a className="rp_clk" data-bs-toggle="modal" data-bs-target="#terms" onClick={handleTermsShow}>Terms and Conditions</a></li>
                            <li>|</li>
                            <li><a className="rp_clk" data-bs-toggle="modal" data-bs-target="#prvcy" onClick={handleprivacyShow}>Privacy Policy</a></li>
                        </ul>
                    </div>
                </div>
            </footer>
            <PrivacyPolicy
                showstatus={showprivacy} 
                hidepopup ={handleprivacyClose}
            />
            <Terms
                showstatus={showTerms} 
                hidepopup ={handleTermsHide}
            />
        </>
    )
}

export default ThankyouFooter;