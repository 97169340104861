import React from "react";
import AnchorAdv from "../../../UI/AnchorAdv";

const AdvAgeBanner = ({ start, end, textHead }) => {
  const textHeadDefault = textHead ? textHead : "How To Get Started";
  const BannerAge = [];
  for (var i = start; i <= end; i++) {
    BannerAge.push(i);
  }
  const Ages = BannerAge.map((age, index) => {
    return (
      <AnchorAdv dataId="MID_PARA_NUM" key={index}>
        {age}
      </AnchorAdv>
    );
  });
  return (
    <>
      <div class="age_button_inner">
        <div class="age_button_inner_bg">
          <h2 class="m-0"> {textHeadDefault} </h2>
          <div class="triangle_shape mx-auto"></div>
          {Ages}
        </div>
      </div>
    </>
  );
};
export default AdvAgeBanner;
