import React, { useState } from "react";
import "../../../assets/FB_ADV1/css/FB_ADV1.scss";
import AdvHeader from '../../Includes/Layouts/FB_ADV1/AdvHeader';
import AdvFooter from '../../Includes/Layouts/FB_ADV1/AdvFooter';
import AnchorAdv from "../../UI/AnchorAdv";
import AdtopiaLoadAP from "../../../Utility/AdtopiaLoadAP";
import { Months } from "../../../Constants/Constants"

const FB_ADV1 = () => {
  
  var now = new Date();
  const year = now.getFullYear()
  const date = now.getDate()
  const monthIndex = now.getMonth();
  const monthName = Months[monthIndex];
  const formatted = ` ${monthName} ${date}, ${year}`;
  const [showTime, setShowTime] = useState(formatted);

  let yearOfBirth1 = [];
  for (var i = 1933; i <= 1972; i++) {
    yearOfBirth1.push(<AnchorAdv key={i} dataId="MID_PARA_NUM" className="banner-btn-color1">{i}</AnchorAdv>);
  }

  let yearOfBirth2 = [];
  for (var i = 1933; i <= 1972; i++) {
    yearOfBirth2.push(<AnchorAdv key={i} dataId="MID_PARA_NUM" className="banner-btn-color1">{i}</AnchorAdv>);
  }

  return (
    <>
      <AdtopiaLoadAP pageType="AP" splitName="over50lifeinsurance/FB_ADV1" />
      <div className="FB_ADV1">
        <AdvHeader />
        <section>
          <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <h1>UK Residents Born Before 1972 Could Save Big on Life Insurance Costs!</h1>
                </div>
                <div className="col-lg-9 col-md-9 col-sm-12 col-12">
                    <AnchorAdv dataId="PARA_IMAGE_1">
                      <img src="/assets/FB_ADV1/img/banner.jpg" alt="" className="banner"/>
                    </AnchorAdv>
                    <p className="mt-3">If you have a partner and/or children, life insurance is a must. If you've put off getting life cover and are now age 50 or older, you've got a great chance to do the right thing for your family as you're now able to get coverage for less than just £10 per month.*</p>
                    <p><strong>There is a big misconception in the UK with respect to life insurance.</strong> Many people think if they're not in their 20s, 30s or 40s, they're too old to be accepted for life insurance. This is a source of stress in countless families because dying isn't cheap! In fact, many families go into debt just to cover the cost of a loved one dying.</p>
                    <div className="greensec p-3 mb-2">
                        <p>Permanent UK residents 50 years of age and older are not only eligible for life insurance, but here are some details that will shock the average reader:</p>
                        <ul>
                            <li>
                              <AnchorAdv dataId="PARA_DESC_1">Premiums are as low as a few quid a week</AnchorAdv>
                            </li>
                            <li>
                            <AnchorAdv dataId="PARA_DESC_1">Guaranteed acceptance for those aged 50-79</AnchorAdv>
                            </li>
                            <li><AnchorAdv dataId="PARA_DESC_1">No medical required</AnchorAdv></li>
                            <li><AnchorAdv dataId="PARA_DESC_1">No health questions</AnchorAdv></li>
                        </ul>
                    </div>
                    <div className="cta_sec_dsk">
                        <div className="row">
                            <div className="col-lg-12 col-xs-12 banner text-center">
                                <h2>HOW TO GET STARTED:</h2>
                                <p>Click your year of birth to get your free life insurance quote</p>
                                {yearOfBirth1}
                            </div>
                        </div>
                    </div>
                    <h2>What Do You Mean - No Medical?</h2>
                    <p>You read that right. If you're aged 50-79 and a permanent UK resident, you are guaranteed to be accepted without having to have a medical or answer any health related questions.</p>
                    <h2>Get Your Quote And Save</h2>
                    <p>The internet is responsible for countless improvements in our lives, and one of them is the ability to shop with ease. Thanks to <AnchorAdv dataId="PARA_DESC_2">sites like this,</AnchorAdv> you spend a minute or two to enter a few details about the kind of cover you're after your age and some other detals and this intemet insurance specialist does the rest and gives you the best quotes available for your requirements.</p>
                    <p>You've put off life cover for long enough - take a couple of minutes and give your family the protection they deserve today.</p>
                    <div className="cta_sec_dsk">
                        <div className="row">
                            <div className="col-lg-12 col-xs-12 banner text-center">
                                <h2>HOW TO GET STARTED:</h2>
                                <p>Click your year of birth to get your free life insurance quote</p>
                                {yearOfBirth2}
                            </div>
                        </div>
                    </div>
                    <div className="col-12 text-center">
                      <AnchorAdv className="blue_btn btn" dataId="MAIN_CTA_BTN">DO I QUALIFY</AnchorAdv>
                    </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-12 col-12 text-center mb-4">
                    <div className="sidebar fixedsticky">
                      <AnchorAdv dataId="RIGHT_SITE_BANNER_1"> 
                        <img src="/assets/FB_ADV1/img/side_bnr.png" alt="" className="side_ban"/> 
                      </AnchorAdv>
                    </div>
                </div>
            </div>
          </div>
        </section>

        <section className="fut">
          <div className="container">
              <div className="col-lg-12">
                  <div className="center-block text-center log">
                    <AnchorAdv className="logo1">
                      <img src="/assets/FB_ADV1/img/log_wht.png" alt="logowht"/>
                    </AnchorAdv>
                  </div>
                  <p className="copyright">
                      Choosing to take out a life insurance policy is a huge step and it can be daunting and scary if you don't know exactly what you are looking for. There are many types of cover available, and many different companies offering it. It is important that you understand the various options that are available to you. Life insurance is not a "one size fits all" situation, so why should you take the first plan that comes along when cheaper and/or more suitable options may be available?
                  </p>
                  <p className="copyright">
                      Taking out a life insurance policy is a big thing to consider for anybody, and you should weigh up your options before deciding on the plan that is right for you. Here at YourLife Insured, we make this process easier by comparing hundreds of life insurance plans from the UK's top insurers to find the most suitable and beneficial plan for you based on your specific situation. Not only will working with us inevitably save you a lot of time, but we are sure to save you money because of the insurer relationship we have.
                  </p>
              </div>
          </div>
        </section>

        <AdvFooter />
      </div>
    </>
  );
};

export default FB_ADV1;
