import React, { useState } from "react";
import ProgressBar from "../YLI00_F5/ProgressBar";
import RadioButton from "../../../UI/RadioButton";

const Year = ({
  className,
  slideChange,
  slidePreChange,
  textHeading,
  validation,
  validationMsg,
  trigger,
  splitForm,
  setError,
  isLeapYear
}) => {

  var arrYears = [];
  for (let i = 1935; i <= 1980; i++) {
    if(isLeapYear){
      if((i % 100 === 0) ? (i % 400 === 0) : (i % 4 === 0)){
        arrYears.push(i);
      }
    }
    else{
      arrYears.push(i);
    }
  }


  const [activestatus, setActivestatus] = useState(-1);

  const changeActiveStatus = (e) => {

      setActivestatus(e.target.id);
      slideChange(e);
  }


  
  return (
    <>
      <div id="slide6" className={`col-12 top_minus_1 ${className}`}>
      <ProgressBar value="70" />
      <div className="col-lg-12 col-md-12 col-sm-12 px-3 form_cont">
        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
            <label>{textHeading}</label>
            <div className="row">
                <div className="col-lg-8 offset-lg-2 col-md-12 col-sm-12 mt-1 dmy mar">

                  {arrYears.map(Year => (  
                    <RadioButton
                      key={Year}
                      value={Year}
                      id={Year}
                      name="DobYear"
                      className="radio-button custom-radio-button sctop1 next-but"
                      labelName={Year}
                      labelClassName={
                        activestatus==Year?"icon_option1 load mobload sctop1 next06 next-but active":
                        "icon_option1 load mobload sctop1 next06 next-but"
                      }
                      onClick={changeActiveStatus}
                      validation={validation()}
                    /> 
                  ))}
                
                </div>
                <span id="fullDOB_err" className="error_msg error"></span>
            </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12 bord text-center">
        <div className="col-12 text-center mt-1">
            <a id="back3" className="back_btn sctop1" name="year_back" onClick={slidePreChange}><img src="/assets/YLI00_F5/img/arrow.png" alt="" />  Previous </a>
        </div>
        <div className="text-center safe-text"> 
            <img src="/assets/YLI00_F5/img/ico-safe.png" alt="Safe, Secure &amp; Confidential" width="12" height="15" />Safe | Secure | Privacy Protected
        </div>
      </div>
      </div>
    </>
  );
};

export default Year;
