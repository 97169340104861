import React from "react";

const CustomCheckBox = ({
  value,
  name,
  checked,
  onChange,
  dataId,
  className,
  labelClassName,
  labelText,
  onClick

}) => {
  return (
    <>
      
      
      <label className={labelClassName} htmlFor={dataId} >
        <input
          type="checkbox"
          className={className}
          value={value}
          id={dataId}
          name={name}
          checked={checked}
          onChange={onChange}
          onClick={onClick}
        />
        <span className="checkmark"></span>
        { labelText}
      </label>
    </>
  );
};
export default CustomCheckBox;
