import React, {useEffect} from "react";
import Header from "../../Includes/Layouts/YLI00_F6/Header";
import Footer from "../../Includes/Layouts/YLI00_F6/Footer";
import "../../../assets/YLI00_F6/css/YLI00_F6.scss";
import FormYLI00_F6 from "../../Forms/FormYLI00_F6";
import AdtopiaLoadLP from "../../../Utility/AdtopiaLoadLP";

const YLI00_F6 = () => {
  useEffect(()=>{
    window.scrollTo(0, 0);
   },[]);
  return (
    <>
      <AdtopiaLoadLP pageType="LP" splitName="over50lifeinsurance/0602YLI00-F6" />
      <div className="YLI00_F6_5">
        <Header />
        <section className="SECT">
          <div className="container">
              <div className="row justify-content-center">
                  <div className="col-lg-12 col-md-12 col-sm-12 pad0" id="formpartsec">
                      <h1 className="d-block d-sm-none d-md-none d-lg-none">Get Free Over 50s <br/><span className="headspan">Life Insurance </span>Quotes</h1>
                      <h2 className="d-block d-sm-none d-md-none d-lg-none">Guaranteed Acceptance For UK Residents Aged 50-80</h2>
                      <div className="form_part">
                        <h1 className="d-none d-sm-block d-md-block d-lg-block">Get Free Over 50s <span className="headspan">Life Insurance </span>Quotes</h1>
                        <h2 className="d-none d-sm-block d-md-block d-lg-block">Guaranteed Acceptance For UK Residents Aged 50-80</h2>
                        <div className="logosec d-none d-sm-block d-md-block d-lg-block">
                            <div className="insurer-logos navbar-expand-lg navbar-light"> 
                                <img src="/assets/YLI00_F6/img/aig.svg" alt="" />
                                <img src="/assets/YLI00_F6/img/aviva.svg" alt=""/>
                                <img src="/assets/YLI00_F6/img/legal-general.svg" alt="" />
                                <img src="/assets/YLI00_F6/img/lve.svg" alt="" />
                                <img src="/assets/YLI00_F6/img/vitality.svg" alt="" />
                                <img src="/assets/YLI00_F6/img/zurich.svg" alt="" />
                                <img src="/assets/YLI00_F6/img/onefamily-logo.png" alt="" />
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center d-lg-none d-sm-none d-md-none d-block">
                            <span className="start-but">Start<br/><img src="/assets/YLI00_F6/img/arrow-b.png" alt="" /></span>
                        </div>
                        <div className="clearfix"></div>
                        <FormYLI00_F6/>
                      </div>
                  </div>
              </div>
          </div>
          <div className="clearfix"></div>
        </section>
        <Footer />
      </div>
    </>
  );
};

export default YLI00_F6;
