import React, { useState } from "react";
import ProgressBar from "../YLI00_F5/ProgressBar";
import TextField from "../../../UI/TextField";
import { NumberValidation } from "../../../../Utility/NumberValidation";
import SelectBox from "../../../UI/SelectBox";
import { AmountCover } from "../../../../Constants/Constants";
const BasicQuestionnaireFive = ({
  className,
  slideChange,
  slidePreChange,
  textHeading,
  validation,
    validationMsg,
    trigger
 // basic_onchangehandle,
 //   namevalidate
}) => {
  let amountCoverTrigger;
  const nextSlide = async (e) => {
   // changeActiveStatus(e);
  // NumberValidation(e);
   amountCoverTrigger = await trigger("amount_cover");
   if (amountCoverTrigger) {
    slideChange(e);
   }
  };
  const setNumberFormat = (e) => {
    const re = /^[1-9]\d*$/;
    if (e.target.value !== "" && !re.test(e.target.value)) {
      e.target.value = e.target.value.replace(/\D/g, "") * 1;
    }
    if (e.target.value !== "") {
      e.target.value = e.target.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  };
  return (
    <>
      <div id="slide11" className={`col-12 top_minus_1 ${className}`}>
        <ProgressBar value="97" />
        <div className="col-lg-12 col-md-12 col-sm-12 px-3">
          <div className="offset-lg-3 col-lg-6 col-md-12 form_cont text-center">
            <div className="row ">
              <div className="form-group">
                <label className="text-center">{textHeading}</label>
                <div className="amt-box">
               {/* <span className="pound_fix"><p>£</p></span> */}
               <SelectBox
                        className="form-control"
                        OptionValue={AmountCover}
                        name="amount_cover"
                        id="amount_cover"
                        //onChange={getValue}
                        myRef={validation({ required: "Please Select Amount of Cover" })}
                        validationMsg={
                          validationMsg.amount_cover && validationMsg.amount_cover.message
                        }
                      />
                {/* <TextField
                    type="tel"
                    className="form-control amt-input"
                    placeholder="Cover Amount"
                    name="amount_cover"
                    id  ="amount_cover"
                    validation={validation({
                      required: "Please Enter Amount of Cover",
                      // minLength: {
                      //   value: 3,
                      //   message: "Please Enter Valid First Name",
                      // },
                      // pattern: {
                      //   value: /^[a-zA-Z\s]+$/,
                      //   message: "Your Name is Invalid. Please Recheck",
                      // },
                    })}
                    validationMsg={
                      validationMsg.amount_cover && validationMsg.amount_cover.message
                    }
                    // onChange ={basic_onchangehandle}
                    // onBlur={namevalidate}
                    keypress={NumberValidation}
                    onKeyUp={(e) => setNumberFormat(e)}
                /> */}
                </div>
              </div>
              <span id="cover_for_err" className="error_msg error"></span>
            </div>
          </div>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 bord text-center">
        <input type="button" id="next03" name="amount_cover" className="next-but btn flasher my-bts sctop1 next03" value="Continue >>" onClick={nextSlide} />
          <div className="col-12 text-center mt-1">
            <a id="back1" className="back_btn sctop1" name="amount_cover_back" onClick={slidePreChange}><img src="/assets/YLI00_F6_5/img/arrow.png" alt="" /> Previous </a>
          </div>
          <div className="text-center safe-text">
            <img src="/assets/YLI00_F6_5/img/ico-safe.png" alt="Safe, Secure &amp; Confidential" width="12" height="15" />Safe | Secure | Privacy Protected
          </div>
        </div>
      </div>
    </>
  );
};
export default BasicQuestionnaireFive;
