import {useState} from 'react';
import {Api} from "../api/Api";

export const useContact = () => {
    const [contactLoading,setContactLoading] = useState(false);
    const contactSubmit = async (phone,email,message) => {
        setContactLoading(true);
        const response = await Api.post("v1/adv_contact", {
            phone,
            email,
            message
        });
        setContactLoading(false);
        return response;
    }
    return {contactSubmit,contactLoading}
}