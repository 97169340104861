import React from 'react';
const UnqualifiedFooter = () =>{
    return(
        <>
            <footer>
               <div class="container">
                  <div class="row">
                     <div class="col-lg-8 lft">
                        <p>ClaimLion Law is a trading name of BlackLion Law LLP, a limited liability partnership registered in England &amp; Wales and regulated by the Solicitors Regulation Authority under SRA number 518911. A list of the members of the LLP is displayed at our registered office at Berkeley Square House, Berkeley Square, London W1J 6BD</p>
                        <p>* If you cancel your claim after the 14-day cancellation period, you may be liable for any expenses we have incurred on your behalf up to the point of cancellation.</p>
                     </div>
                     <div class="col-lg-4 rht"><img src="/assets/Unqualified/img/ftr_img.png" alt=""/></div>
                     <div class="col-lg-12">
                        <div class="copyright">
                           <ul>
                              <li><a class="popupLink" data-bs-toggle="modal" data-bs-target="#Privacy"> Privacy Policy</a></li>
                              <li><a class="popupLink" href="https://yousted.org/lfdesigns/Sass-Projects/HomeLoanCredit/ver-01/dist/doc/TERMS_OF_BUSINESS.788f5f2e.pdf" target="_blank">Terms of Business</a></li>
                              <li><a class="popupLink"  data-bs-toggle="modal" data-bs-target="#Cookie">Cookie Policy</a> </li>
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>
            </footer>
        </>
    )
}

export default UnqualifiedFooter;