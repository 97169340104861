import React from "react";
import "../../../assets/YLI_ADV3/css/YLI_ADV3.scss";
import AdvHeader from "../../Includes/Layouts/YLI_ADV3/AdvHeader";
import AdvFooter from "../../Includes/Layouts/YLI_ADV3/AdvFooter";
import AdvAgeBanner from "../../Includes/Layouts/YLI_ADV3/AdvAgeBanner";
import AnchorAdv from "../../UI/AnchorAdv";
import AdtopiaLoadAP from "../../../Utility/AdtopiaLoadAP";

const YLI_ADV3 = () => {
  return (
    <>
      <AdtopiaLoadAP pageType="AP" splitName="over50lifeinsurance/YLI_ADV3" />
      <div className="YLI_ADV3">
        <AdvHeader />
        <section>
          <div className="container">
            <div className="row">
              <div className="col-xl-9 col-lg-9 lft_sec">
                <h1>
                  Families are shocked to receive more than £242,160 when
                  checking their life insurance
                </h1>
                <h2>
                  Thousands are jumping at this opportunity to help protect
                  their family's financial security for the future.
                </h2>
                <AnchorAdv dataId="PARA_IMAGE_1">
                  <img
                    src="/assets/YLI_ADV3/img/ParaImg1.jpg"
                    alt=""
                    className="w-100"
                  />
                </AnchorAdv>
                <p className="yellow_link mt-2">
                  <span>
                    <AnchorAdv dataId="PARA_TEXT_LINKS_1">
                      Find out if you are eligible!
                    </AnchorAdv>
                  </span>
                </p>
                <p className="mt-3">
                  <strong>
                    Young parents could be eligible for life insurance policies
                    worth more than £242,160.
                  </strong>{" "}
                  This opportunity to help ensure your family's financial
                  security won't last forever, so{" "}
                  <strong>
                    <AnchorAdv dataId="PARA_TEXT_LINKS_2">
                      act fast to check your eligibility and secure your rates
                      now.
                    </AnchorAdv>
                  </strong>
                </p>
                <p>
                  Getting a life insurance quote should be quick and easy, but
                  many can find it difficult to secure a policy that will
                  protect their family.
                </p>
                <div className="col-12 text-center d-block d-sm-block d-md-block d-lg-none d-xl-none mb-3">
                  <AnchorAdv dataId="RIGHT_SITE_BANNER_1" className="">
                    <img
                      src="/assets/YLI_ADV3/img/age-banner.png"
                      alt=""
                      className="w-100 tabwidth"
                    />
                  </AnchorAdv>
                </div>
                <p>
                  Don't spend hours searching for the best policy from multiple
                  providers, follow the easy steps below to get a FREE
                  personalised quote built for your family and your needs.
                </p>
                <h3 className="mb-3">Here's How to Get Started</h3>
                <p>
                  <strong>Step 1:</strong> Use our simple form to answer a few
                  questions (it takes just two minutes)
                </p>
                <p>
                  <strong>Step 2:</strong> Once we have your details, we'll get
                  you talking to a consultant.
                </p>

                <section class="partnersec text-center py-3">
                  <div class="container">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                      <div class="row">
                        <div class="col-xl-12 col-lg-12 col-12 age_button_sec text-center">
                          <AdvAgeBanner
                            textHead={"Select Your Age"}
                            start={20}
                            end={69}
                          />
                        </div>
                      </div>
                      <AnchorAdv
                        dataId="MAIN_CTA_BTN"
                        className="btn main_btn zoom"
                      >
                        Get Your FREE Quotes
                      </AnchorAdv>
                    </div>
                  </div>
                </section>
              </div>
              <div className="col-xl-3 col-lg-3 text-center d-none d-sm-none d-md-none d-lg-block d-xl-block">
                <div className="sidebar fixedsticky">
                  <AnchorAdv dataId="RIGHT_SITE_BANNER_1" className="">
                    <img
                      src="/assets/YLI_ADV3/img/age-banner.png"
                      alt=""
                      className="w-100"
                    />
                  </AnchorAdv>
                </div>
              </div>
            </div>
          </div>
        </section>
        <AdvFooter />
      </div>
    </>
  );
};

export default YLI_ADV3;
