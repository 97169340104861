import React from 'react';
const ThankyouHeader = () =>{
    return(
        <>
        <header>
            <div className="container">
                <div className="row">
                <div className="col-lg-12 logo">
                    <img src="/assets/Thankyou/img/logo.png" alt=""/>  
                </div>
                
                </div>
            </div>
        </header>
        </>
    )
}

export default ThankyouHeader;