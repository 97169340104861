

import React, { Suspense, lazy } from 'react';
import FallBack from './Constants/FallBack';

const MCS_V1 = lazy(() => import('./Components/Pages/Splits/MCS_V1'));
const YLI00_F5 = lazy(() => import('./Components/Pages/Splits/YLI00_F5'));
const YLI00_F6 = lazy(() => import('./Components/Pages/Splits/YLI00_F6'));
const YLI00_F6_5 = lazy(() => import('./Components/Pages/Splits/YLI00_F6_5'));
const YLI00_F6_6 = lazy(() => import('./Components/Pages/Splits/YLI00_F6_6'));
const YLI00_F7 = lazy(() => import('./Components/Pages/Splits/YLI00_F7'));
const YLI00_G1 = lazy(() => import('./Components/Pages/Splits/YLI00_G1'));


const AppSplitList = (props) => {

    const SplitListMap = {
        'MCS_V1': MCS_V1,
        'over50lifeinsurance/0602YLI00-F5': YLI00_F5,
        'over50lifeinsurance/0602YLI00-F6': YLI00_F6,
        'over50lifeinsurance/0602YLI00-F6.5': YLI00_F6_5,
        'over50lifeinsurance/0602YLI00-F6.6': YLI00_F6_6,
        'over50lifeinsurance/0602YLI00-F7': YLI00_F7,
        '0602YLI00-G1': YLI00_G1
    };
    const SplitComponent = SplitListMap[props.splitName];
    return (
        <>
            <Suspense fallback={<FallBack />}>
                {SplitComponent && <SplitComponent />}
            </Suspense>
        </>
    )

}

export default AppSplitList;