import React, { useState } from "react";
import ProgressBar from "../YLI00_F5/ProgressBar";
import CustomCheckBox from "../../../UI/CustomCheckBox";

const BasicQuestionnaireThree = ({
  className,
  slideChange,
  slidePreChange,
  textHeading,
  validation,
  getValues,
  setLookingForInsuranceList
}) => {
  const [lookingForInsuranceList] = useState([]);
  const [showError, setError] = useState('hide');
  const nextSlide = async (e) => {
    if(checkBoxValidation()){
      slideChange(e);
    }
  };

  const onSelectLookingForInsurance = (e) => {
    if(e.target.checked === true){
      lookingForInsuranceList.push({ value: e.target.value });
      checkBoxValidation();
    }
    else{
      lookingForInsuranceList.pop({ value: e.target.value });
      checkBoxValidation();
    }
    setLookingForInsuranceList(lookingForInsuranceList);
  };

  const checkBoxValidation = (e) => {
    if(lookingForInsuranceList.length > 0){
      setError('hide');
      return true;
    }
    
    setError('show');
    return false;
    
  }
  return (
    <>
      <div id="slide3" className={`col-12 top_minus_1 ${className}`}>
        <ProgressBar value="20" />
        <div className="col-lg-12 col-md-12 col-sm-12 px-3 form_cont">
          <div className="col-lg-12 col-md-12 col-sm-12 form-group">
              <label>{textHeading}</label>
              <p className="text-center paragrap">Please check all answers that apply</p>
              <div className="row">
                  <div className="col-lg-12 col-md-12">
                      <div className="col-lg-8 offset-lg-2 col-md-8 offset-md-2 col-sm-12 col-12">
                          <ul className="options">
                              <li className="popupLink mt-3">
                                <CustomCheckBox
                                    value="3"
                                    labelText="Cover end of life expenses such as funeral costs"
                                    name="lookingForInsurance[]"
                                    labelClassName="defaultchk"
                                    dataId="LookingForInsurance1"
                                    onChange= {onSelectLookingForInsurance}
                                />
                              </li>

                              <li className="popupLink mt-3">
                                <CustomCheckBox
                                    value="4"
                                    labelText="Leave financial security for my family"
                                    name="lookingForInsurance[]"
                                    labelClassName="defaultchk"
                                    dataId="LookingForInsurance2"
                                    onChange= {onSelectLookingForInsurance}
                                />
                              </li>

                              <li className="popupLink mt-3">
                                <CustomCheckBox
                                    value="5"
                                    labelText="Leave money for an organization, such as a personal business or charity"
                                    name="lookingForInsurance[]"
                                    labelClassName="defaultchk"
                                    dataId="LookingForInsurance3"
                                    onChange= {onSelectLookingForInsurance}
                                />
                              </li>

                              <li className="popupLink mt-3">
                                <CustomCheckBox
                                    value="6"
                                    labelText="Other"
                                    name="lookingForInsurance[]"
                                    labelClassName="defaultchk"
                                    dataId="LookingForInsurance4"
                                    onChange= {onSelectLookingForInsurance}
                                />
                              </li>
                              
                          </ul>
                          <div className={`col-lg-12 text-center ${showError}`} >
                            <span className="error_msg text-center">Please Check All Answers that Apply</span>
                          </div>
                      </div>
                  </div>
                  <span id="fullDOB_err" className="error_msg error"></span>
              </div>
          </div>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 bord text-center">
            <input type="button" id="next03" name="lookingForInsurance_btn" className="next-but btn flasher my-bts sctop1 next03" value="Continue >>" onClick={nextSlide} />
            <div className="col-12 text-center mt-1">
              <a id="back1" className="back_btn sctop1" name="lookingForInsurance_btn_back" onClick={slidePreChange}><img src="/assets/YLI00_F5/img/arrow.png" alt="" /> Previous </a>
            </div>
            <div className="text-center safe-text"> 
              <img src="/assets/YLI00_F5/img/ico-safe.png" alt="Safe, Secure &amp; Confidential" width="12" height="15" />Safe | Secure | Privacy Protected
            </div>
        </div>
      </div>
    </>
  );
};
export default BasicQuestionnaireThree;
