import React from 'react';
const UnqualifiedHeader = () =>{
    return(
        <>
            <header>
               <div class="container">
                  <div class="row">
                     <div class="col-lg-6 offset-lg-3 col-12 text-center">
                        <img src="/assets/Unqualified/img/logo.png" alt=""/>
                     </div>
                  </div>
               </div>
            </header>
        </>
    )
}

export default UnqualifiedHeader;