import React, { useState, useEffect, useReducer } from 'react';
import { queryString, urlParams } from "../../../Utility/QueryString";
import "../../../assets/Followup/css/Followup.scss";
import InputButton from '../../UI/InputButton';
import SignatureModal from '../../Includes/Layouts/Followup/SignatureModal';
import { Api } from "../.././../api/Api";
import { useFollowupLoad } from "../../../Hooks/useFollowupLoad";
import Loader from "../../Includes/Layouts/Followup/Loader";
import { useHistory } from "react-router-dom";

var complete_arr = [];
const initialState = {
  showButton1: "hide",
  showTick1: "hide",
};
const FormReducer = (state, action) => {
  switch (action.type) {
    case "showButton": {
      if (action.payload.pendingStatus.status_btn == "signature") {
        return {
          ...state,
          showButton1: "show",
          showTick1: "hide",
        };
      }
      else {
        return {
          ...state,
        };
      }
    }
    case "showTick": {
      if (action.payload.pendingStatus.status_btn == "signature") {
        return {
          ...state,
          showTick1: "show",
          showButton1: "hide",
        };
      }
      else {
        return {
          ...state,
        };
      }
    }
  }
};
const Followup = () => {
  const [signatureClass, SetSignatureClass] = useState(false);
  const [pendingStatus, setPendingStatus] = useState([]);
  const [showLoad, setshowLoad] = useState("show");
  const [showUserName, setUserName] = useState("");
  const [allFollowupData, setFollowupData] = useState({});
  const [state, dispatch] = useReducer(FormReducer, initialState);
  const { loadFollowup, isuseFollowupLoad } = useFollowupLoad();
  const [pending, setPending] = useState(true);
  const [followupStatus, setFollowupStatus] = useState('Pending');
  const [currentUuid, setCurrentUuid] = useState('');
  const history = useHistory();
  const userToken = urlParams.get("atp_sub2");
  const atpSub6 = (urlParams.get("atp_sub6")) ? urlParams.get("atp_sub6") : 'FLP';
  localStorage.setItem('user_token', userToken);
  localStorage.setItem('atp_sub6', atpSub6);
  const setLoader = (e) => {
    setshowLoad("show");
  };
  const hideLoader = (e) => {
    setshowLoad("hide");
  };
  const showThankYou = (uuid) => {
    history.push('/thankyou?uuid=' + uuid);
  };
  useEffect(() => {
    (async () => {
      const response = await Api.get("/api/v1/followup/get-pending-details?query_string=" + queryString, {}
      );
      const followupDetails = response.data.response.followup_data;
      if (userToken == null) {
        localStorage.setItem('user_token', followupDetails.atp_sub2);
      }
      setUserName(response.data.response.user_info.user_name
      );
      setFollowupData({
        flpData: response.data.response
      });
      localStorage.setItem('user_info', JSON.stringify(response.data.response.user_info));
      setCurrentUuid(response.data.response.user_info.uuid);
      if (response.data.status === "Success") {
        const flpResponse = await loadFollowup(
          "followup_load",
          followupDetails,
          "TMS_BRN_V1",
          queryString,
          "v2"
        )
        setTimeout(() => { hideLoader() }, 2000);
        if (response.data.response.pending_details.is_user_complete === 1) {
          showThankYou(response.data.response.user_info.uuid);
          return false;
        }
        if (response.data.response.pending_details.is_user_sign === 0) {
          setPendingStatus({ status_btn: 'signature', status_flag: 'showButton' });
        }
        else {
          setPendingStatus({ status_btn: 'signature', status_flag: 'showTick' });
        }
      }
    })();
  }, []);
  useEffect(() => {
    if (pendingStatus) {
      dispatch({ type: pendingStatus.status_flag, payload: { pendingStatus } });
      if (pendingStatus.status_flag == "showTick") {
        complete_arr.push(pendingStatus.status_btn);
        if (complete_arr.indexOf("signature") > -1) {
          setPending(false);
          setFollowupStatus('Review Stage');
        }
      }
    }
  }, [pendingStatus]);
  return (
    <div className="followupPage">
      <div className="bglogo">
        <section>
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 l_bg">
                <form>
                  <Loader showLoad={showLoad}></Loader>
                  <div id="myDiv2" className="slide2" style={showLoad === "hide" ? { display: "block" } : { display: "none" }} >
                    <div className="blue-bg col-12">
                      <h4 style={{ color: '#fff' }}>Claim Status: {followupStatus}</h4>
                      <p>Almost done! We just need you to review your claim documents and we can begin the process.</p>
                    </div>
                    <div className="col-12 data-div mb20 animated fadeInUp">
                      <div className=" col-12 nopad">
                        <table className="table table-bordered">
                          <thead className="thead-light">
                            <tr>
                              <th className="align-middle" width="60%">Account Holder  </th>
                              <th width="40%">{showUserName ? showUserName : null}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Digital Signature</td>
                              <td className={state ? `text-center align-middle blink_me  ${state.showButton1}` : `hide`}>
                                <InputButton
                                  id=""
                                  value="Sign here"
                                  btnType="button"
                                  onClick={() => SetSignatureClass(true)}
                                  className="add-btn signbut"
                                />
                              </td>
                              <td className={state ? `text-center align-middle signyes ${state.showTick1}` : `hide`}>
                                <img
                                  src="/assets/Followup/img/y-but.png"
                                  alt=""
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div id="mybut">
                      <InputButton
                        id=""
                        value="REVIEW + SUBMIT"
                        btnType="button"
                        onClick={() => showThankYou(currentUuid)}
                        className={`btn-submit button1 pop_but ${pending == true ? '' : 'animated-effect'}`}
                        disabled={pending}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div >
      <SignatureModal
        popDisplay={signatureClass}
        allFollowupData={allFollowupData}
        setPendingStatus={setPendingStatus}
        setLoader={setLoader}
        hideLoader={hideLoader}
        showUserName={showUserName}
      />
    </div >
  )
}

export default Followup;