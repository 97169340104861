import React from 'react';
import AdtopiaLoadRoot from '../../Utility/AdtopiaLoadRoot';

const HomePage = () => {
  const renderSplitName = () => {
  }
  return (
    <>
      <AdtopiaLoadRoot pageType="home" renderSplitName={renderSplitName} splitName="/over50lifeinsurance/0602YLI00-F5" />
    </>
  )
}

export default HomePage;