import React, { useState } from "react";
import { PreferredTimeForCall } from "../../../../Constants/Constants";
import SelectBox from "../../../UI/SelectBox";
import Telephone from "../../../UI/Telephone";
import InputButton from "../../../UI/InputButton";
import ProgressBar from "../YLI00_F6/ProgressBar";
import PrivacyPolicy from '../PrivacyPolicy';

const AdditionalDetails = ({
  className,
  slidePreChange,
  textHeading,
  validation,
  validationMsg,
  basic_onchangehandle,
  phoneValidCheck,
  reff,
  disabled,
  formSubmit,
  setPreferredTimeForCall,
  buttonValue,
  progressVal
}) => {

  const [showprivacy, setshowprivacy] = useState(false);

  const handleprivacyShow=()=>{
    setshowprivacy(true);
  };
  const handleprivacyClose=()=>{
    setshowprivacy(false);
  };

  const setTime=(e)=>{
    e.preventDefault();
    setPreferredTimeForCall(e.target.value);
  };

  return (
    <>
    <div id="slide9" className={`col-12 top_minus_1 ${className}`}>
      <ProgressBar value={progressVal} />
      <div className="col-lg-12 col-md-12 col-sm-12 px-3 form_cont">
          <div className="col-lg-12 col-md-12 col-sm-12 form-group">
              <label>{textHeading}</label>
              <div className="row">
                  <div className="col-lg-8 offset-lg-2 col-md-12 col-sm-12 mt-1 dmy">
                      <p className="text-center para"><strong>Do you have an additional phone number?</strong></p>

                      <div className=" form-group" ref={(el) => (reff.current['txtAlterPhone'] = el)}>
                        <Telephone
                          name="txtAlterPhone"
                          className="form-control"
                          placeholder="Second Mobile Phone (optional)"
                          maxlength="11"
                          onBlur={phoneValidCheck}
                          onKeyPress={e => (!/[0-9]+$/.test(e.key)) && e.preventDefault()}
                          validation={validation({ required: false })}
                          validationMsg={
                            validationMsg.txtPhone && "Please Enter Valid Phone Number"
                          }
                          onChange ={basic_onchangehandle}
                        ></Telephone>
                      </div>
                  </div>
                  <div className="col-lg-8 offset-lg-2 col-md-12 col-sm-12 mt-1 dmy">
                      <p className="text-center para"><strong>What is your preferred time for a call?</strong></p>
                      <div className=" form-group" ref={(el) => (reff.current['preferredTimeForCall'] = el)}>
                        <SelectBox
                          className="form-control"
                          OptionValue={PreferredTimeForCall}
                          name="preferredTimeForCall"
                          id="PreferredTimeForCall"
                          validation={validation()}
                          onChange={setTime}
                        />
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12 bord text-center">
          <InputButton
            style={{ float: "none" }}
            name="submit_form"
            className="next-but btn my-bt sctop1 next09"
            value="Get My Free Quote &gt;&gt;"
            //value="Continue >>"
            //value={buttonValue}
            btnType="button"
            onClick={formSubmit}
            disabled={disabled}
            id="btn_submit_claim"
          />
          <div className="col-12 text-center mt-1">
              <a id="back7" className="back_btn sctop" name="submit_form_back" onClick={slidePreChange}><img src="/assets/YLI00_F6/img/arrow.png" alt="" />  Previous </a>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 text-center">
              <div className="col-lg-8 offset-lg-2 col-12 authorise text-center">
                  <p className='d-none'>You will receive a call from an FCA authorised life insurance advisor if we feel we can help you based on the information you have provided.</p>
                  <p className=''>By continuing you will receive a call from an FCA authorised life insurance consultant if we feel we can help you based on the information you have provided. More details can be found in our <a className="rp_clk" data-bs-toggle="modal" data-bs-target="#prvcy" onClick={handleprivacyShow}>Privacy Policy</a></p>
              </div>
          </div>
          <div className="text-center safe-text"> 
              <img src="/assets/YLI00_F6/img/ico-safe.png" alt="Safe, Secure &amp; Confidential" width="12" height="15" />Safe | Secure | Privacy Protected
          </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12 text-center d-none">
          <div className="offset-lg-2 col-lg-8 col-lg-offset-2 terms mob-0">
              <div className="consent text-center">
                  <p>By submitting your enquiry, you agree to our <a className="rp_clk" data-bs-toggle="modal" data-bs-target="#prvcy" onClick={handleprivacyShow}>Privacy Policy</a> and agree we may use the contact details provided to contact you about your life insurance quote.
                  </p>
              </div>
          </div>
      </div>
    </div>
    <PrivacyPolicy
      showstatus={showprivacy} 
      hidepopup ={handleprivacyClose}
    />
    </>
  );
};
export default AdditionalDetails;
