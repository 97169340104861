export const Salutation = [
  {
    label: "Title",
    value: "",
  },
  {
    label: "Mr.",
    value: "Mr",
  },
  {
    label: "Mrs.",
    value: "Mrs",
  },
  {
    label: "Ms.",
    value: "Ms",
  },
  {
    label: "Dr.",
    value: "Dr",
  },
  {
    label: "Prof.",
    value: "Prof",
  },
];

export const DobDay = [{ label: "Day", value: "" }];
const startDay = 1;
const endDay = 31;
for (var i = startDay; i <= endDay; i++) {
  var objDobDay = {};
  objDobDay["label"] = i;
  objDobDay["value"] = i;
  DobDay.push(objDobDay);
}

export const DobMonth = [
  { label: "Month", value: "" },
  { label: "January", value: "January" },
  { label: "February", value: "February" },
  { label: "March", value: "March" },
  { label: "April", value: "April" },
  { label: "May", value: "May" },
  { label: "June", value: "June" },
  { label: "July", value: "July" },
  { label: "August", value: "August" },
  { label: "September", value: "September" },
  { label: "October", value: "October" },
  { label: "November", value: "November" },
  { label: "December", value: "December" },
];

export const Months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const DobYear = [{ label: "Select Year of Birth", value: "" }];
const startYear = 1989;
const endYear = 1910;
for (var k = startYear; k >= endYear; k--) {
  var objDob = {};
  objDob["label"] = k;
  objDob["value"] = k;
  DobYear.push(objDob);
}
const BannerYearAdv1 = [];
const start = 1926;
const end = 1989;
for (var i = start; i <= end; i++) {
  if (start === i) {
    BannerYearAdv1.push([-i]);
  } else {
    BannerYearAdv1.push([i]);
  }
}
export { BannerYearAdv1 };
export const signCheckText = `By signing and by pressing “Submit” below, you agree to Check for SSB Group <a href="https://www.ssblaw.co.uk/uploads/files/website-terms-of-use-and-privacy-policy-may-19.pdf" target="_blank">Terms and Conditions</a> and that we may use your signature on the <a style="cursor:pointer; color:#00aab1 !important;" href=""> Letter of Authority</a> that we send to your bank.  A copy of the <a href="https://www.ssblaw.co.uk/uploads/files/website-terms-of-use-and-privacy-policy-may-19.pdf" target="_blank">Terms and Conditions</a> and <a style="cursor:pointer; color:#00aab1 !important" href="">Letter of Authority</a> will be sent to you via email.
`;

export const PreferredTimeForCall = [
  {label : "Preferred Time to Speak", value : ""},
  /*{label : "08:00 am", value : "08:00 am"},
  {label : "09:00 am", value : "09:00 am"},
  {label : "10:00 am", value : "10:00 am"},
  {label : "11:00 am", value : "11:00 am"},
  {label : "12:00 pm", value : "12:00 pm"},
  {label : "01:00 pm", value : "01:00 pm"},
  {label : "02:00 pm", value : "02:00 pm"},
  {label : "03:00 pm", value : "03:00 pm"},*/
  {label : "Morning", value : "Morning"},
  {label : "Afternoon", value : "Afternoon"},
  {label : "Evening", value : "Evening"},
];
export const CoverTerm = [{ label: "Select Cover Term", value: "" }];
const startCoverYear = 1;
const endCoverYear = 35;
for (var k = startCoverYear; k <= endCoverYear; k++) {
  var objDob = {};

  objDob["label"] = (k == 1) ? k+' Year' :k+' Years';
  objDob["value"] = (k == 1) ? k+' Year' :k+' Years';
  CoverTerm.push(objDob);
}

// export const AmountCover = [
//   { label: "Select Cover Amount", value: "" },
//   { label: "0 - 5,00,000", value: "0 - 5,00,000" },
//   { label: "5,00,000 - 10,00,000", value: "5,00,000 - 10,00,000" },
//   { label: "10,00,000 - 15,00,000", value: "10,00,000 - 15,00,000" },
//   { label: "15,00,000 - 20,00,000", value: "15,00,000 - 20,00,000" },
//   { label: "20,00,000 - 25,00,000", value: "20,00,000 - 25,00,000" },
//   { label: "25,00,000 - 30,00,000", value: "25,00,000 - 30,00,000" },
//   { label: "30,00,000 - 35,00,000", value: "30,00,000 - 35,00,000" },
//   { label: "35,00,000 - 40,00,000", value: "35,00,000 - 40,00,000" },
//   { label: "40,00,000 - 45,00,000", value: "40,00,000 - 45,00,000" },
//   { label: "45,00,000 - 50,00,000", value: "45,00,000 - 50,00,000" },
// ];

export const AmountCover = [
  { label: "Select Cover Amount", value: "" },
  { label: "0 - 50,000", value:"0 - 50,000" },
  { label: "50,000 - 100,000", value: "50,000 - 100,000" },
  { label: "100,000 - 200,000", value: "100,000 - 200,000" },
  { label: "200,000 - 300,000", value: "100,000 - 200,000" },
  { label: "300,000 - 400,000", value: "300,000 - 400,000" },
  { label: "400,000 - 500,000", value: "400,000 - 500,000" },
  { label: "500,000 - 600,000", value: "500,000 - 600,000" },
  { label: "600,000 - 700,000", value: "600,000 - 700,000" },
  { label: "700,000 - 800,000", value: "700,000 - 800,000" },
  { label: "800,000 - 900,000", value: "800,000 - 900,000" },
  { label: "900,000 - 1,000,000", value: "900,000 - 1,000,000" },
 ];
