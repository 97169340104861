import React from 'react';
import AnchorAdv from "../../../UI/AnchorAdv";
import logo from "../../../../assets/YLI_GLI_ADV1/img/logo.png";
import arrow from "../../../../assets/YLI_GLI_ADV1/img/arrow.svg";
import FbViewPixel from '../../../../Utility/FbViewPixel';
const AdvHeader = () => {
  return (
    <>
      <FbViewPixel />
      <div className="header">
      <div className="header-heading">
        <div className="row m-0">
          <div className="col-lg-6 col-md-6 col-sm-6 col-6 logo_img">
          <AnchorAdv dataId="SITE_lOGO">
              <img className="img-responsive" src={logo} height="50px"/>
            </AnchorAdv>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-6 check_btn">
            <AnchorAdv className="btn btn-primary btn-blue" dataId="TOP_CTA_BTN">
               Check Your Eligibility <img className="img-arrow" src={arrow} alt="" />
            </AnchorAdv>
       
          </div>
        </div>
      </div>
    </div>
    </>
  )
}
export default AdvHeader;
