import React, { useState } from "react";
import ProgressBar from "./ProgressBar";
import TextField from "../../../UI/TextField";
import SelectBox from "../../../UI/SelectBox";
import { CoverTerm } from "../../../../Constants/Constants";
const BasicQuestionnaireFive = ({
  className,
  slideChange,
  slidePreChange,
  textHeading,
  validation,
    validationMsg,
    trigger
 // basic_onchangehandle,
 //   namevalidate
}) => {
  let coverTermTrigger;
  const nextSlide = async (e) => {
    coverTermTrigger = await trigger("cover_term");
    if(coverTermTrigger){
      slideChange(e);
    }
    
  };


  return (
    <>
      <div id="slide2" className={`col-12 top_minus_1 ${className}`}>
        <ProgressBar value="40" />
        <div className="col-lg-12 col-md-12 col-sm-12 px-3">
          <div className="offset-lg-3 col-lg-6 col-md-12 form_cont text-center">
            <div className="row ">
              <div className="form-group">
                <label className="text-center">{textHeading}</label>
                <SelectBox
                        className="form-control"
                        OptionValue={CoverTerm}
                        name="cover_term"
                        id="cover_term"
                        //onChange={getValue}
                        myRef={validation({ required: "Please Select Cover Term" })}
                        validationMsg={
                          validationMsg.cover_term && validationMsg.cover_term.message
                        }
                      />
              </div>
              <span id="cover_for_err" className="error_msg error"></span>
            </div>
          </div>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 bord text-center">
        <input type="button" id="next03" name="cover_term" className="next-but btn flasher my-bts sctop1 next03" value="Continue >>" onClick={nextSlide} />
          <div className="col-12 text-center mt-1">
            <a id="back1" className="back_btn sctop1" name="cover_term_back" onClick={slidePreChange}><img src="/assets/YLI00_F5/img/arrow.png" alt="" /> Previous </a>
          </div>
          <div className="text-center safe-text">
            <img src="/assets/YLI00_F5/img/ico-safe.png" alt="Safe, Secure &amp; Confidential" width="12" height="15" />Safe | Secure | Privacy Protected
          </div>
        </div>
      </div>
    </>
  );
};
export default BasicQuestionnaireFive;
