import React, { useState } from 'react';
import logo from "../../../../assets/YLI_GLI_ADV1/img/logo.png";
import Terms from '../Terms';
import PrivacyPolicy from '../PrivacyPolicy';

const AdvFooter = () => {

    const [showprivacy, setshowprivacy] = useState(false);
    const [showpcookies, setshowpcookies] = useState(false);
  
    const handleprivacyShow = () => {
      setshowprivacy(true);
    };
    const handleprivacyClose = () => {
      setshowprivacy(false);
    };
    const handleCookieShow = () => {
      setshowpcookies(true);
    };
    const handleCookieHide = () => {
      setshowpcookies(false);
    };
  return (
    <>
  <footer>
      <hr className="mt-4 pb-4"/>
      <div className="col-lg-12 logo_img text-center">
        <a href="#">
          <img className="img-responsive" src={logo} height="40px"/>
        </a>
      </div>
        <p className="small">Life Insurance prices are effected by things such as age, health, and lifestyle circumstances</p>
        <p className="small">We do not charge a fee for this service, but we do receive a commission directly from our partners following successful introductions from this website.</p>
        <p className="small">Any information we provide is for the purpose of illustrating the products promoted on this site only and should not be read as financial advice.</p>
        <p className="small"><i>*All prices are dependent on factors such as age, health, and lifestyle, therefore may vary.</i></p>
        <p className="small"><i>*£10 per month for £200,000 coverage is based on a 30 year old non-smoker looking for 35 years of level term life cover, or a 30 year old smoker looking for 30 years of decreasing term cover.</i></p>
        <p className="small"><i>*35p per day is based on the same £10 per month premium over a full 12 months.</i></p>
        <p className="small">Copyright © YourLife Insured. 2022 | All Rights Reserved</p>

    <ul className="links pt-3">
      <li>
        <a href="#terms" data-bs-toggle="modal" onClick={handleCookieShow} data-bs-target="#terms">Terms and Conditions</a>
      </li>
      <li>|</li>
      <li>
        <a href="#prvcy" data-bs-toggle="modal" onClick={handleprivacyShow} data-bs-target="#prvcy" >Privacy Policy</a>
      </li>
    </ul>
    </footer>

      <PrivacyPolicy showstatus={showprivacy} hidepopup={handleprivacyClose} />
      <Terms showstatus={showpcookies} hidepopup={handleCookieHide} />
    </>
  )
}
export default AdvFooter;
